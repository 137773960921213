import { Directive } from "vue";

const clickOutside: Directive = {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event: Event) {
      if (!(el === event.target || el.contains(event.target))) {
        if (typeof binding.value !== "function") {
          console.warn("Directive argument not a function");
          return;
        }
        binding.value(event.target);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
};
export default clickOutside;
