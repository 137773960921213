import { User as CmsUser } from "@workspace/open-api/cms";
import store from "../../store";
import { importScript } from "@two-ui/util/script-loader";

export async function initializeHotjar() {
  eval(`(function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 2588612, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");`);
}

export async function trackHotjarEvent(event: string) {
  const w = window as any;
  if (!w.hj) {
    console.warn(`Hotjar not loaded, not tracking event: ${event}`);
    return;
  }
  w.hj("event", event);
  console.debug(`Hotjar event tracked: ${event}`);
}

export async function identifyUser(user: CmsUser) {
  console.debug("Setting up Hotjar for current user");
  const w = window as any;
  if (!w.hj) {
    console.warn(`Hotjar not loaded, so will not identify user in Hotjar data`);
    return;
  }
  const userToIdentify = {
    id: user.id,
    email: user.emailAddress,
    first_name: user.firstName,
    last_name: user.lastName,
    customer_ids: user.customerIds.join(","),
  };
  console.debug(
    "Current user has consented to data processing, identify user with Hotjar",
    userToIdentify
  );

  w.hj("identify", user.id, userToIdentify);
}
