<script lang="ts">
import { defineComponent, onMounted } from "vue";
import {
  Auth0EmailVerificationRequiredError,
  Auth0InvalidStateError,
  doLogin,
  getUser,
  handleLoginCallback,
} from "@two-ui/auth0";
import { useRouter } from "vue-router";
import { getRouteBasedOnAuthenticatedUserState } from "@two-ui/router";
import {
  getUserAndTheirOnboardingState,
  initializeStoreAndPluginsFromUser,
  initializeTracking,
} from "@two-ui/user";
import FullPageLoader from "@two-ui/components/FullPageLoader.vue";
import { doLogout } from "@two-ui/auth0";
import { UserOnboardingState } from "@two-ui/types";
import { trackEvent } from "@two-ui/mixpanel";
import { useGlobalStore } from "../global-store";
import store from "@two-ui/store";
import { BOOK_A_DEMO_URL } from "@two-ui/constants";
import { removeCustomerOnboardingFlagIfExists } from "@two-ui/services/onboarding";

export default defineComponent({
  components: {
    FullPageLoader,
  },
  setup() {
    const router = useRouter();
    onMounted(async () => {
      try {
        await handleLoginCallback();
      } catch (e) {
        if (e instanceof Auth0EmailVerificationRequiredError) {
          // User has not verified email, tell them to
          console.log(e.emailAddress);
          router.push({
            name: "onboarding-verify-email",
            query: { email: e.emailAddress },
          });
        } else if (e instanceof Auth0InvalidStateError) {
          // retry login after auth0 invalid state error.
          // If user is already authencticated, it will logged in automatically.
          doLogin();
        } else {
          // Unsure what to do, logout the user
          console.error("Error while handling auth callback:", e);
          doLogout();
        }
        return;
      }

      const { user, state } = await getUserAndTheirOnboardingState();
      if (user) {
        const auth0User = await getUser();
        const auth0Token = await useGlobalStore().token();
        await initializeStoreAndPluginsFromUser(user, auth0User!, auth0Token);
        if (
          !store.state.featureToggles.enableConsentBanner ||
          user.options?.dataProcessingTncAccepted
        ) {
          initializeTracking(user);
        }
      }
      if (state === UserOnboardingState.UnknownState) {
        window.location.href = BOOK_A_DEMO_URL;
        return;
      }
      if (state === UserOnboardingState.BelongsToActiveCustomer) {
        removeCustomerOnboardingFlagIfExists();
        trackEvent("User logged in");
      }
      router.push(await getRouteBasedOnAuthenticatedUserState(state));
    });
  },
});
</script>

<template>
  <FullPageLoader>Just logging you in…</FullPageLoader>
</template>
