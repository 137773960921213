import { useGlobalStore } from "@two-ui/global-store";
import store from "@two-ui/store";
import { RouteLocationNormalized } from "vue-router";

export class BalanceTncAcceptedError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export default async function balanceTncAcceptedRestrictionGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) {
  if (to.meta.requiresBalancesTncAccepted) {
    await store.waitForUserPropertiesToPopulate();
    if (!useGlobalStore().useBalancesTncAccepted) {
      if (!from.name) return { name: "home" };
      throw new BalanceTncAcceptedError();
    }
  }
}
