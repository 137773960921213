<template>
  <div>
    <div class="mb-2 flex" v-if="label">
      <FieldLabel
        :label="label"
        :show-optional-hint="showOptionalHint"
        :for="id"
        :description="description"
      />
      <!-- Character count -->
      <label v-if="charCount && maxLength" class="char-count"
        >{{ count }}/{{ maxLength }}</label
      >
    </div>
    <FieldGradientBorderWrapper
      :show-error="
        showRequiredValidation || showCustomValidation || showPatternValidation
      "
    >
      <div
        class="field-inner-border field-padding field-background-light flex focus-within:outline-none"
      >
        <textarea
          v-if="multiline"
          @blur="onBlur()"
          :maxlength="maxLength"
          rows="5"
          class="field-background-light field-text-light field-placeholder flex-1 outline-none"
          :class="{ 'sentry-mask': sentryMask }"
          :id="id"
          type="text"
          @keydown="preventKeyDown"
          @keyup.enter="onEnter"
          @input="onInput"
          @change="onChange"
          :value="modelValue"
          :placeholder="placeholder"
        >
        </textarea>
        <input
          v-else
          :id="id"
          class="field-placeholder field-text-light field-background-light ml-2 flex-1 outline-none"
          :class="{ 'sentry-mask': sentryMask }"
          :type="type"
          :value="modelValue"
          @focus="$emit('focus')"
          @blur="onBlur()"
          @keydown="preventKeyDown"
          @keyup.enter="onEnter"
          @input="onInput"
          @change="onChange"
          :placeholder="placeholder"
          :maxlength="maxLength"
          :autocomplete="autocomplete ? 'on' : 'off'"
        />
        <!-- Clear icon -->
        <button
          type="button"
          v-if="clearable && modelValue"
          style="line-height: 1"
          class="mr-3 text-black"
          @click="emitAllValueUpdateEvents('')"
        >
          <font-awesome-icon
            class="field-icon-light"
            :icon="['far', 'times']"
          ></font-awesome-icon>
        </button>
        <!-- Validation exclamation icon -->
        <font-awesome-icon
          v-if="
            (showRequiredValidation ||
              showCustomValidation ||
              showPatternValidation) &&
            canShowSuffixIcon
          "
          class="mr-3 text-red-700"
          :icon="['far', 'triangle-exclamation']"
        ></font-awesome-icon>
        <!-- Custom suffix icon -->
        <font-awesome-icon
          v-if="suffixIcon && canShowSuffixIcon"
          :icon="suffixIcon"
          class="field-icon mr-3"
        ></font-awesome-icon>
        <span
          class="ml-1 mr-3 whitespace-nowrap"
          v-if="suffixText && canShowSuffixText"
          >{{ suffixText }}</span
        >
      </div>
    </FieldGradientBorderWrapper>
    <!-- Error texts -->
    <div>
      <p class="field-error-text" v-if="showRequiredValidation">
        This field is required.
      </p>
      <p class="field-error-text" v-else-if="showCustomValidation">
        {{ customError }}
      </p>
      <p class="field-error-text" v-if="showPatternValidation">
        {{ patternCustomError }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import FieldGradientBorderWrapper from "./FieldGradientBorderWrapper.vue";
import FieldLabel from "./FieldLabel.vue";

export default defineComponent({
  components: { FontAwesomeIcon, FieldGradientBorderWrapper, FieldLabel },
  emits: [
    "update:modelValue",
    "change",
    "input",
    "focus",
    "blur",
    "keyup.enter",
  ],
  props: {
    label: {
      type: String,
    },
    /**
     * Description to show below the field label
     */
    description: {
      type: String,
    },
    /**
     * For changing input type attribute
     */
    type: {
      type: String,
      default: "text",
    },
    /**
     * Max length of characters that can be entered.
     */
    maxLength: {
      type: Number,
    },
    placeholder: {
      type: String,
    },
    modelValue: {
      type: String,
    },
    /**
     * To show text indicating field is required below the field after touched
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * To show icon for clearing the field
     */
    clearable: {
      type: Boolean,
      default: false,
    },
    suffixIcon: {
      type: [String, Array, Object] as PropType<typeof FontAwesomeIcon["icon"]>,
    },
    /**
     * Show any custom error text below the field.
     * Will show required text first is required prop is set and field is empty.
     */
    customError: { type: String },
    /**
     * Make the text field a multiline text area
     */
    multiline: { type: Boolean, default: false },
    /**
     * Input autocomplete attribute
     */
    autocomplete: { type: Boolean, default: false },
    /**
     * Does not allow entering specific keys in the field
     */
    preventKeys: { type: Array as PropType<string[]>, default: () => [] },
    /**
     * Show number of characters remaining out of total allowed characters specified by maxLength prop.
     */
    charCount: { type: Boolean, default: false },
    /**
     * Used to indicate in label that field is optional
     */
    showOptionalHint: { type: Boolean, default: false },
    // Mark field to be masked by the Sentry replay feature
    sentryMask: { type: Boolean, default: false },
    suffixText: { type: String },
    pattern: { type: String, default: null },
    patternCustomError: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const id = uuidv4();
    const touched = ref(false);
    const showRequiredValidation = computed(
      () => touched.value && props.required && !props.modelValue
    );
    const showCustomValidation = computed(
      () => touched.value && !!props.customError
    );
    const showPatternValidation = computed(() => {
      return !!(
        touched.value &&
        props.pattern &&
        !new RegExp(props.pattern).test(props.modelValue as string)
      );
    });
    const count = computed(() => {
      return props.modelValue ? props.modelValue.length : 0;
    });
    const canShowSuffixIcon = computed(
      () => !(props.clearable && props.modelValue)
    );
    const canShowSuffixText = computed(
      () => !(props.clearable && props.modelValue && props.suffixIcon)
    );
    const emitAllValueUpdateEvents = (value: string) => {
      emit("update:modelValue", value);
      emit("input", value);
      emit("change", value);
      emit("keyup.enter", value);
    };

    const onChange = (event: Event) => {
      const { value } = event.target as HTMLInputElement;
      emit("update:modelValue", value);
      emit("change", value);
    };

    const onInput = (event: Event) => {
      const { value } = event.target as HTMLInputElement;
      emit("update:modelValue", value);
      emit("input", value);
    };

    const onEnter = (event: Event) => {
      const { value } = event.target as HTMLInputElement;
      emit("update:modelValue", value);
      emit("keyup.enter", value);
    };

    const onBlur = () => {
      touched.value = true;
      emit("blur");
    };

    const preventKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey || event.metaKey) {
        // Don't prevent using keyboard shortcuts
        return;
      }
      if (props.preventKeys.includes(event.key)) {
        console.debug("Prevented typing of key");
        event.preventDefault();
      }
    };

    return {
      id,
      touched,
      showCustomValidation,
      showPatternValidation,
      showRequiredValidation,
      count,
      canShowSuffixIcon,
      onChange,
      onInput,
      onBlur,
      onEnter,
      preventKeyDown,
      emitAllValueUpdateEvents,
      canShowSuffixText,
    };
  },
});
</script>
<style lang="sass" scoped>
@import "../styles/field"

.char-count
  @apply ml-auto text-sm text-grey-500 font-light
</style>
