const ONBOARDING_NEW_CUSTOMER_FLAG_KEY = "onboardingNewCustomer";

/**
 * Store to identify if customer visited via onboarding link and needs to be onboarded
 */
export function storeCustomerOnboardingFlag() {
  localStorage.setItem(ONBOARDING_NEW_CUSTOMER_FLAG_KEY, "true");
}

/**
 * To check if customer visited via onboarding link and needs to be onboarded
 */
export function customerOnboardingFlagExists() {
  return !!localStorage.getItem(ONBOARDING_NEW_CUSTOMER_FLAG_KEY);
}

/**
 * Remove flag that indicates customer needs to onboarded
 */
export function removeCustomerOnboardingFlagIfExists() {
  localStorage.removeItem(ONBOARDING_NEW_CUSTOMER_FLAG_KEY);
}
