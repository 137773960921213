<script lang="ts" setup>
const props = defineProps({
  color: {
    type: String,
    default: "#555",
  },
  strokeWidth: {
    type: String,
    default: "3",
  },
  size: {
    type: String,
    default: "100",
  },
});
</script>

<template>
  <svg
    data-testid="loading-spinner"
    class="spinner"
    xmlns="http://www.w3.org/2000/svg"
    :width="props.size"
    :height="props.size"
    viewBox="0 0 100 100"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      :stroke="props.color"
      :stroke-width="props.strokeWidth"
      r="40"
      stroke-dasharray="164 56"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
</template>
