<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    label?: string;
    for?: string;
    showOptionalHint?: boolean;
    description?: string;
  }>(),
  { showOptionalHint: false }
);
</script>
<template>
  <div>
    <label :for="props.for" class="field-label-text">
      {{ label }}
      <span class="field-optional-hint" v-if="showOptionalHint"
        >(optional)</span
      >
    </label>
    <p class="field-description-text" v-if="description">
      {{ description }}
    </p>
  </div>
</template>
<style lang="sass" scoped>
@import "../styles/field"
</style>
