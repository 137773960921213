<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    src: string;
    label?: string;
    size?: "small" | "medium" | "large" | "xlarge" | "xxlarge";
  }>(),
  {
    label: "Avatar",
    size: "small",
  }
);

const label = computed(() => {
  return props.label ? props.label : "Avatar";
});
</script>

<template>
  <div
    :class="{
      'h-10 w-10': size === 'xxlarge',
      'h-9 w-9': size === 'xlarge',
      'h-8 w-8': size === 'large',
      'h-7 w-7': size === 'medium',
      'h-6 w-6': size === 'small',
    }"
    class="overflow-hidden rounded-full"
  >
    <img
      :src="src"
      alt="avatar"
      :aria-label="label"
      class="h-full w-full object-cover"
    />
  </div>
</template>
