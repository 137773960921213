/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SignatureResponse } from "../models/SignatureResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class TokenService {
  /**
   * Signs a user's email address using HMAC SHA256.
   * Signs a user's email address using HMAC SHA256 and the key provided by Intercom.
   * @returns SignatureResponse The operation succeded, and the body contains the hashed email signature.
   * @throws ApiError
   */
  public static getTokenForUser(): CancelablePromise<SignatureResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/token/user",
      errors: {
        400: `Bad request`,
        401: `Auth required`,
        403: `Unauthorized`,
        500: `Internal server error`,
      },
    });
  }
}
