<template>
  <table class="w-full">
    <thead>
      <tr>
        <TwoTableHeader class="w-10" v-if="showIndexColumn">#</TwoTableHeader>

        <slot name="header" />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(rowData, index) in innerTableData"
        :key="index"
        class="duration-100 hover:bg-grey-50"
        :class="rowClass"
        @click="$emit('rowClick', rowData, index)"
      >
        <TwoTableColumn v-if="showIndexColumn">{{ index + 1 }}</TwoTableColumn>
        <slot name="row" v-bind:rowData="rowData" />
      </tr>
      <tr v-if="innerTableData.length === 0">
        <td colspan="100%" class="py-6 text-center text-sm text-grey-400">
          <template v-if="!$slots.noRecordsFound">
            {{ notRecordFoundText }}
          </template>
          <slot name="noRecordsFound"></slot>
        </td>
      </tr>
    </tbody>
  </table>
  <slot name="emptyData" />
</template>

<script lang="ts">
import _ from "lodash";
import { defineComponent, PropType, provide, ref, watch } from "vue";
import {
  CurrentColumnSort,
  TableColumnSortOrder,
  TableColumnSortProvider,
} from "../types";
import TwoTableColumn from "./TwoTableColumn.vue";
import TwoTableHeader from "./TwoTableHeader.vue";
const DEFAULT_INITIAL_SORT_ORDER_FOR_COL: TableColumnSortOrder = "asc";

function getNextSortOrderForColumn(
  currentSortOrder: TableColumnSortOrder
): TableColumnSortOrder {
  if (currentSortOrder === "asc") return "desc";
  else if (currentSortOrder === "desc") return "asc";
  return DEFAULT_INITIAL_SORT_ORDER_FOR_COL;
}
export default defineComponent({
  components: { TwoTableColumn, TwoTableHeader },
  props: {
    tableData: { type: Array as PropType<Record<string, any>[]> },
    showIndexColumn: { type: Boolean, default: false },
    rowClass: { type: String, default: "" },
    notRecordFoundText: { type: String, default: "No record found." },
  },
  emits: ["rowClick"],
  setup(props) {
    const innerTableData = ref(props.tableData || []);
    const currentColumnSort = ref<CurrentColumnSort>({});

    watch(
      () => props.tableData,
      () => {
        if (props.tableData) innerTableData.value = props.tableData;
      }
    );

    const sortColumn = (dataKey: string) => {
      const nextSortOrder = getNextSortOrderForColumn(
        currentColumnSort.value[dataKey]
      );
      //reset sort for all columns
      for (let key in currentColumnSort.value)
        delete currentColumnSort.value[key];
      // set next sort for current column
      currentColumnSort.value[dataKey] = nextSortOrder;
      //perform sort
      innerTableData.value = _.orderBy(
        innerTableData.value,
        [dataKey],
        [nextSortOrder]
      );
    };
    provide<TableColumnSortProvider>("columnSorter", {
      sortColumn,
      currentColumnSort,
    });
    return {
      innerTableData,
    };
  },
});
</script>
