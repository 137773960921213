<template>
  <button
    :type="type"
    class="btn"
    :class="{
      round,
      shadowed,
      circle,
      wide,
      borderLess,
    }"
    :disabled="disabled || loading"
  >
    <LoadingSpinner
      color="#AAAAAA"
      strokeWidth="7"
      v-if="loading"
      class="-mt-1 mr-2 inline h-4 w-4"
    />
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import LoadingSpinner from "./LoadingSpinner.vue";
import RUNA from "@wegift/company-brand-assets/dist/design-system/json/tailwind-theme.json";
export default defineComponent({
  components: { LoadingSpinner },
  props: {
    type: {
      type: String as PropType<"button" | "submit" | "reset">,
      default: "button",
    },
    round: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    shadowed: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    borderLess: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="sass" scoped>
@import "../styles/field"


// Default button styles
.btn
  @apply font-bold px-4 py-3 bg-white text-sm text-black rounded-xl border-2 border-black
  @apply hover:bg-grey-50
  @apply focus-visible:outline-none focus-visible:ring focus-visible:ring-cyan-300
  @apply active:bg-grey-100
  @apply disabled:opacity-50 disabled:pointer-events-none
  &:not(:last-child)
    @apply mr-2

// Button variants
.btn-primary, .btn-black
  @apply bg-black text-white border-black
  @apply hover:bg-grey-700 hover:bg-grey-700 hover:border-grey-700
  @apply active:bg-grey-800

.btn-secondary
  @apply bg-grey-50 text-black border-grey-50
  @apply hover:bg-grey-100 hover:border-grey-100
  @apply active:bg-grey-200

.btn-danger
  @apply bg-red-700 text-white border-red-700
  @apply hover:bg-red-800 hover:border-red-800
  @apply active:bg-red-900

.btn-dark
  @apply bg-grey-600 text-white border-grey-600
  @apply hover:bg-grey-800 hover:text-grey-100 hover:border-grey-800
  @apply active:bg-grey-900

.btn-success
  @apply bg-lime-600 text-white border-lime-600
  @apply hover:bg-lime-500 hover:border-lime-500
  @apply active:bg-lime-700

.btn-gradient
  @apply bg-gradient-primary text-black font-semibold border-none
  @apply hover:brightness-90
  @apply active:brightness-75

// Modifier classes that can be applied in addition to button variant class
.btn-lg
  @apply text-lg

.btn-no-outline
  @apply focus-visible:ring-0

// Classes for internal use set based on props
.round
  @apply rounded-full

.shadowed
  @apply shadow-md hover:shadow-lg
  &:hover
    // Using pseudo class as cannot add disabled:shadow-md to tailwind for some reason
    @apply shadow-md

.circle
  @apply rounded-full h-9 w-9 inline-flex items-center justify-center text-grey-600 border border-grey-50

.wide
  @apply py-3 px-32

.borderLess
  @apply border-none
</style>
