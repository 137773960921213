<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import TheSidebar from "@two-ui/components/TheSidebar.vue";
import EnvironmentMark from "@two-ui/components/EnvironmentMark.vue";
import JiraMark from "@two-ui/components/JiraMark.vue";
import ConsentBannerModal from "@two-ui/components/ConsentBannerModal.vue";
import { useRoute, useRouter } from "vue-router";
import { IntercomChatClient } from "@two-ui/services/intercom";
import {
  checkIfUserAuthenticated,
  getUser,
  restoreAuthState,
} from "@two-ui/auth0";
import FullPageLoader from "@two-ui/components/FullPageLoader.vue";
import ImportImages from "@two-ui/ImportImages.vue";
import { BOOK_A_DEMO_URL } from "@two-ui/constants";
// Load application fonts
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@wegift/company-brand-assets/dist/design-system/css/font-f37Lineca.css";

// App global styles
import "@two-ui/styles/global.sass";
import "@two-ui/styles/extensions.sass";
import "@two-ui/styles/type.sass";
import "@wegift/two-components/dist/style.css";

// External libraries styles
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

import {
  canEnterCurrentRouteWithoutAuth,
  getRouteBasedOnAuthenticatedUserState,
  isCurrentRouteAnOnboardingRoute,
} from "@two-ui/router";
import {
  getUserAndTheirOnboardingState,
  initializeStoreAndPluginsFromUser,
  initializeTracking,
} from "@two-ui/user";
import { UserFromToken, UserOnboardingState } from "@two-ui/types";
import store from "@two-ui/store";
import { useGlobalStore } from "@two-ui/global-store";
import { useBreadCrumb } from "@two-ui/sub-stores/breadCrumb";
import { removeCustomerOnboardingFlagIfExists } from "@two-ui/services/onboarding";

const router = useRouter();
const route = useRoute();
const breadCrumbStore = useBreadCrumb();

breadCrumbStore.generateBreadCrumb(route);

let user: UserFromToken;

const userPermissions = computed(() => store.state.userPermissions?.join(","));

// Fetch the state of the user and either redirect somewhere or initialise the store
const initAndCheckAuthState = async () => {
  if (canEnterCurrentRouteWithoutAuth()) {
    return;
  }
  const auth0User = await getUser();
  // Refresh token on page reload
  const auth0Token = await useGlobalStore().token({ ignoreCache: true });
  const isLoggedIn = await restoreAuthState();
  if (!(auth0User && isLoggedIn)) {
    router.push({ name: "auth-login" });
    return;
  }
  const userAndState = await getUserAndTheirOnboardingState();
  const { state } = userAndState;
  user = userAndState.user;
  if (state === UserOnboardingState.UnknownState) {
    window.location.href = BOOK_A_DEMO_URL;
    return;
  }
  if (state !== UserOnboardingState.BelongsToActiveCustomer) {
    router.push(await getRouteBasedOnAuthenticatedUserState(state));
  } else {
    removeCustomerOnboardingFlagIfExists();
    // take user to home page if user belongs to a valid customer but is on an onboarding route
    if (isCurrentRouteAnOnboardingRoute()) {
      router.push({ name: "home" });
    }
  }

  if (user) {
    initializeStoreAndPluginsFromUser(user, auth0User, auth0Token);
    if (
      !store.state.featureToggles.enableConsentBanner ||
      user.options?.dataProcessingTncAccepted
    ) {
      initializeTracking(user);
    }
  }
};

const onDataProcessingTermsAccepted = async () => {
  if (!user) {
    // fetch user again if does not exist. This can happen in case of Auth Callback route as
    // initAndCheckAuthState returns before setting user in that case
    user = (await getUserAndTheirOnboardingState()).user;
  }
  if (user) initializeTracking(user);
};

const awaitingAuthChecks = ref(true);

onMounted(async () => {
  // Expose the router and route to the window for remote-app use
  window.globalRouter = router;
  window.globalRoute = route;
  // Wait for the auth checks to finish and init the store
  await initAndCheckAuthState();
  // Remove the loading state and allow the route to render
  awaitingAuthChecks.value = false;
});

onMounted(async () => {
  // Only load the chat client if the user's logged in. This particular
  // call to initialize is only expected to be called on reload of our app.
  if (await checkIfUserAuthenticated()) {
    IntercomChatClient.initialize();
  }
  const appElement = document.getElementById("app");
  if (appElement) {
    // Remove styling applied to the app element before the app has loaded.
    appElement.classList.remove("preload");
  }
});

onUnmounted(async () => {
  IntercomChatClient.shutdown();
});

// Controls whether the 'chrome' (sidebar and other bits) are shown.
// We disable them for pre-auth state as they need auth to function.
// We disable them for some routes for a 'full screen' view
// We disable them when route is not resolved
const isSidebarHiddenForRoute = computed(() => {
  const routeResolved = route.matched.length > 0;
  return !routeResolved || route.meta.preAuth || route.meta.hideSidebar;
});

watch(
  () => route.path,
  () => {
    breadCrumbStore.generateBreadCrumb(route);
  }
);
</script>

<template>
  <JiraMark />
  <EnvironmentMark />
  <FullPageLoader v-if="awaitingAuthChecks">Getting set up</FullPageLoader>
  <router-view v-else-if="isSidebarHiddenForRoute" />
  <div
    v-else
    class="grid grid-cols-[230px_1fr] lg:grid-cols-[260px_1fr] xl:grid-cols-[270px_1fr]"
    :key="userPermissions"
  >
    <TheSidebar />
    <!-- Add key to router-view so that it reloads on customerId change -->
    <router-view
      :key="store.state.customerId ?? undefined"
      class="container mx-auto w-full px-4 pb-10 pt-5 md:px-6 xl:px-8"
    />
    <import-images image="logo.png" />
    <ConsentBannerModal @accept="onDataProcessingTermsAccepted" />
  </div>
</template>
