<script lang="ts" setup>
import TwoCheckbox from "@two-components/components/TwoCheckbox.vue";
import TwoAccordion from "@two-components/components/TwoAccordion.vue";
import TwoAccordionItem from "@two-components/components/TwoAccordionItem.vue";
import TwoButton from "@two-components/components/TwoButton.vue";
import { trackEvent } from "@two-ui/mixpanel";
import { computed, ref } from "vue";
import { getTimezone } from "@two-ui/util/l10n";
import { formatDateTime } from "@two-ui/util/date";
import { useGlobalStore } from "@two-ui/global-store";
import { CustomerSource } from "@workspace/open-api/cms";
import { UserPermissions } from "@two-ui/types";
import TwoMessageBox from "@workspace/packages/two-components/src/components/two-messagebox/TwoMessageBox.vue";

trackEvent("User Settings: Viewed page");

const globalStore = useGlobalStore();
const customerId = computed(() => globalStore.customerId);
const useUTC = ref(globalStore.timezoneOverride === "Etc/UTC");
const currentTimezone = ref(getTimezone());
const currentTimezoneCity = computed(() => currentTimezone.value.split("/")[1]);
const canViewConnectBalanceThresholds = computed(
  () =>
    globalStore.customerSource === CustomerSource.MIGRATED_FROM_CONNECT &&
    (globalStore.userPermissions?.includes(
      UserPermissions.WRITE_TOP_UP_BY_BANK
    ) ||
      globalStore.userPermissions?.includes(
        UserPermissions.WRITE_TOP_UP_BY_CARD
      ))
);

const handleNavigateToBalanceNotification = () => {
  trackEvent("User Settings: Clicked on Notification Preferences");
  window.open(
    `${
      import.meta.env.VITE_SCION_BASE_URL
    }/connect/preferences/notification?customer=${customerId.value}`,
    "_blank"
  );
};

const timezoneSettingChanged = (isChecked: boolean) => {
  trackEvent("User Settings: Toggled UTC", { isChecked });
  if (isChecked) {
    globalStore.setTimezoneOverride("Etc/UTC");
  } else {
    globalStore.clearTimezoneOverride();
  }
  currentTimezone.value = getTimezone();
};
</script>

<template>
  <main class="max-w-screen-lg">
    <div class="font-f37lineca flex items-center justify-between pt-5">
      <h1 class="my-6 text-4xl">Settings</h1>
    </div>
    <div>
      <TwoAccordion :allow-multiple-expanded="true">
        <TwoAccordionItem
          v-if="canViewConnectBalanceThresholds"
          title="Balance notifications"
          :is-large-title="true"
        >
          <div>
            <TwoMessageBox
              header="Looking for your balance warning thresholds?"
              :hide-icon="true"
            >
              <p>
                You can edit your settings by following the link below. Please
                contact our Customer Service team if you need any additional
                support.
              </p>
              <TwoButton
                data-testid="learn-more"
                @click="handleNavigateToBalanceNotification"
                class="mt-2"
              >
                Notification preferences
                <font-awesome-icon
                  :icon="['far', 'fa-arrow-up-right-from-square']"
                  class="ml-1 self-center"
                />
              </TwoButton>
            </TwoMessageBox>
          </div>
        </TwoAccordionItem>
        <TwoAccordionItem
          title="Your timezone"
          :is-large-title="true"
          :is-open-on-load="!canViewConnectBalanceThresholds"
        >
          <TwoCheckbox
            v-model="useUTC"
            @change="timezoneSettingChanged"
            class="text-base"
            >Use UTC for dates and times</TwoCheckbox
          >
          <div class="mb-[0.2rem] ml-[2rem] mt-[1rem] text-base">
            <p v-if="currentTimezone === 'Etc/UTC'">
              Times will be shown in <b>UTC</b>.<sup>&dagger;</sup>
            </p>
            <p v-else>
              Times will be shown in your local timezone,
              <b>{{ currentTimezoneCity }}</b
              >.<sup>&dagger;</sup>
            </p>
          </div>
          <div class="ml-[2rem] rounded bg-grey-50 px-4 py-3">
            <div class="flex-lg">
              <div>
                <p class="text-center text-sm">
                  The date and time will be displayed as follows
                </p>
                <p class="font-f37lineca mt-1 text-center text-2xl">
                  <b>{{ formatDateTime(new Date().toISOString()) }}</b
                  ><sup>&ddagger;</sup>
                </p>
              </div>
            </div>
          </div>
          <div class="ml-[2rem] text-sm">
            <p class="mt-[1rem]">
              <sup>&dagger;</sup> The timezone in Billing and Finance will
              always be displayed in UTC, regardless of the setting here.
            </p>
            <p class="mt-[1rem]">
              <sup>&ddagger;</sup> There are some cases where the date and time
              might have a different format from that shown here. This is due to
              technical limitations.
            </p>
          </div>
        </TwoAccordionItem>
      </TwoAccordion>
    </div>
  </main>
</template>

<style>
.font-f37lineca {
  font-family: F37 Lineca;
  font-weight: 850;
}
</style>
