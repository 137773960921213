import { TokenService } from "@workspace/open-api/intercom-token-service";
import { getUser } from "@two-ui/auth0";

/**
 * Controls the Intercom Chat client.
 */
export class IntercomChatClient {
  // Values provided by Intercom. The app id is _not_ a secret and it can be
  // seen in request data (so it's OK to commit it to a VCS).
  public static readonly INTERCOM_API_BASE = "https://api-iam.intercom.io";
  public static readonly INTERCOM_APP_ID = "zp2wue34";

  /**
   * Initialises and loads the Intercom Chat client. This method should only be
   * called for users that are logged in.
   */
  public static async initialize() {
    let user = await getUser();
    let name = user?.given_name ?? user?.nickname ?? "friend";

    // Call our signing function in the "Intercom Token Service" service.
    //  This will extract the logged in user's email address from the JWT.
    let signatureResponse;
    try {
      signatureResponse = await TokenService.getTokenForUser();
    } catch (error) {
      console.error(
        "Error while getting token to start intercom service.",
        error
      );
      return;
    }

    // This call is what actually adds the client to the UI. If the chat client
    // is not visible, it may be due to settings configured on Intercom's
    // website.
    window.Intercom("boot", {
      api_base: this.INTERCOM_API_BASE,
      app_id: this.INTERCOM_APP_ID,
      name: name,
      email: signatureResponse.user_email,
      user_hash: signatureResponse.user_hash,

      // This is meant to be a unix timestamp of when the user's account was
      // created. This doesn't appear to acutally be important from a UI/UX
      // perspective though, so we just set it to the EPOCH.
      created_at: "0",
    });
  }

  /**
   * Unloads the Intercom Chat client.
   */
  public static shutdown() {
    window.Intercom("shutdown");
  }

  /**
   * This function updates the Intercom Chat client with any changes when the
   * user navigates around the app.
   */
  public static update() {
    window.Intercom("update");
  }

  /**
   * This function shows the Intercom Chat client.
   */
  public static show() {
    window.Intercom("show");
  }
}
