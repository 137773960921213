/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerDetailResponse } from "../models/CustomerDetailResponse";
import type { CustomerExistsResponse } from "../models/CustomerExistsResponse";
import type { CustomerListResponse } from "../models/CustomerListResponse";
import type { CustomerOrderLimitsResponse } from "../models/CustomerOrderLimitsResponse";
import type { CustomerOrderLimitsSetRequest } from "../models/CustomerOrderLimitsSetRequest";
import type { CustomerUserCollection } from "../models/CustomerUserCollection";
import type { OnboardingStateResponse } from "../models/OnboardingStateResponse";
import type { UpdateCustomerSegmentRequest } from "../models/UpdateCustomerSegmentRequest";
import type { UpdateCustomerSegmentResponse } from "../models/UpdateCustomerSegmentResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class CustomerService {
  /**
   * Get customer list
   * @returns CustomerListResponse OK
   * @throws ApiError
   */
  public static getCustomerList(): CancelablePromise<CustomerListResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/customer",
      errors: {
        401: `Unauthorized`,
        404: `No customers found`,
      },
    });
  }

  /**
   * Update an existing customer with optional survey info
   * @param requestBody
   * @returns UpdateCustomerSegmentResponse OK
   * @throws ApiError
   */
  public static updateCustomerSegment(
    requestBody: UpdateCustomerSegmentRequest
  ): CancelablePromise<UpdateCustomerSegmentResponse> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/customer",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `ValidationErrorResponse`,
      },
    });
  }

  /**
   * Get customer creation state
   * @returns OnboardingStateResponse OK
   * @throws ApiError
   */
  public static getOnboardingState(): CancelablePromise<OnboardingStateResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/onboarding-state",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `No customers found`,
      },
    });
  }

  /**
   * Get existing customer ID
   * Check whether a customer already exists by using domain
   * @param domain
   * @returns CustomerExistsResponse OK
   * @throws ApiError
   */
  public static getCustomerExistsByDomain(
    domain: string
  ): CancelablePromise<CustomerExistsResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/customer/exists",
      query: {
        domain: domain,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `ValidationErrorResponse`,
      },
    });
  }

  /**
   * Get details about a customer
   * Returns details about a customer, requires that the user is a member of that customer.
   * @param customerId
   * @returns CustomerDetailResponse OK
   * @throws ApiError
   */
  public static getCustomerById(
    customerId: string
  ): CancelablePromise<CustomerDetailResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/customer/{customerId}",
      path: {
        customerId: customerId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get all users against a particular customerID
   * Get all users against a particular customerID
   * @param customerId
   * @returns CustomerUserCollection OK
   * @throws ApiError
   */
  public static getUsersForCustomer(
    customerId: string
  ): CancelablePromise<CustomerUserCollection> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/customer/{customerId}/users",
      path: {
        customerId: customerId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `ValidationErrorResponse`,
      },
    });
  }

  /**
   * Remove a user from a customer
   * Remove a user from a customer
   * @param customerId
   * @param userId
   * @returns void
   * @throws ApiError
   */
  public static removeUserFromCustomer(
    customerId: string,
    userId: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/customer/{customerId}/user/{userId}",
      path: {
        customerId: customerId,
        userId: userId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get a list of order limits for a customer
   * Get a list of order limits for a customer
   * @param customerId
   * @returns CustomerOrderLimitsResponse OK
   * @throws ApiError
   */
  public static getCustomerOrderLimits(
    customerId: string
  ): CancelablePromise<CustomerOrderLimitsResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/customer/{customerId}/limits",
      path: {
        customerId: customerId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Set the order limits for a customer
   * Set the order limits for a customer
   * @param customerId
   * @param requestBody
   * @returns CustomerOrderLimitsResponse OK
   * @throws ApiError
   */
  public static setCustomerOrderLimits(
    customerId: string,
    requestBody: CustomerOrderLimitsSetRequest
  ): CancelablePromise<CustomerOrderLimitsResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/customer/{customerId}/limits",
      path: {
        customerId: customerId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `ValidationErrorResponse`,
      },
    });
  }
}
