/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Transaction = {
  /**
   * The transaction ID.
   */
  id: string;
  /**
   * The transaction type.
   */
  transaction_type: Transaction.transaction_type;
  /**
   * The transaction sub-type.
   */
  transaction_sub_type: Transaction.transaction_sub_type;
  /**
   * A more verbose description of the transaction.
   */
  description?: string;
  /**
   * The source transaction id
   */
  source_transaction_id: string;
  /**
   * The order id
   */
  order_id?: string;
  /**
   * The currency of the transaction, in ISO-4217 format (3 letter code).
   */
  currency: string;
  /**
   * The currency of the transaction, in ISO-4217 format (3 letter code). It could be different from base currency for FX Transactions
   */
  transaction_currency?: string;
  /**
   * The timestamp of the transaction in ISO 8601 format, with the UTC timezone.
   */
  timestamp: string;
  /**
   * The amount of the transaction.
   */
  amount: string;
  /**
   * The amount of the transaction which might be different from base amount for FX Transactions.
   */
  transaction_amount?: string;
  /**
   * Whether the transaction is credit/debit
   */
  transaction_direction: Transaction.transaction_direction;
  /**
   * The face_value in case of an order item.
   */
  face_value?: string;
  /**
   * The product_code used for order item.
   */
  product_code?: string;
  /**
   * The source of an order item.
   */
  order_source?: Transaction.order_source;
};

export namespace Transaction {
  /**
   * The transaction type.
   */
  export enum transaction_type {
    ADJUSTMENT = "ADJUSTMENT",
    CURRENCY_EXCHANGE = "CURRENCY_EXCHANGE",
    FEES = "FEES",
    ORDER_ITEM = "ORDER_ITEM",
    TOP_UP = "TOP_UP",
  }

  /**
   * The transaction sub-type.
   */
  export enum transaction_sub_type {
    BANK_TRANSFER = "BANK_TRANSFER",
    CANCELLATION = "CANCELLATION",
    CARD_PAYMENT = "CARD_PAYMENT",
    CARD_PAYMENT_FEE = "CARD_PAYMENT_FEE",
    COMPLETED = "COMPLETED",
    CREDIT = "CREDIT",
    DELAYED_DISCOUNT = "DELAYED_DISCOUNT",
    DISCOUNT_ADJUSTMENT = "DISCOUNT_ADJUSTMENT",
    FX_FEES = "FX_FEES",
    FX_TRANSFERS_IN = "FX_TRANSFERS_IN",
    FX_TRANSFERS_OUT = "FX_TRANSFERS_OUT",
    OTHER = "OTHER",
    POS_CARD_PAYMENT = "POS_CARD_PAYMENT",
    POS_CARD_PAYMENT_FEE = "POS_CARD_PAYMENT_FEE",
    REACTIVATION = "REACTIVATION",
    REBATE_BY_RUNA = "REBATE_BY_RUNA",
    RUNA_FUNDS_ADVANCE = "RUNA_FUNDS_ADVANCE",
    RUNA_FUNDS_RECALL = "RUNA_FUNDS_RECALL",
    UNSPENT_VALUE = "UNSPENT_VALUE",
  }

  /**
   * Whether the transaction is credit/debit
   */
  export enum transaction_direction {
    CREDIT = "CREDIT",
    DEBIT = "DEBIT",
  }

  /**
   * The source of an order item.
   */
  export enum order_source {
    PORTAL = "PORTAL",
    QUICK_ORDER = "QUICK_ORDER",
    ORDER_TEMPLATE = "ORDER_TEMPLATE",
    RUNA_WEB_APP = "RUNA_WEB_APP",
    SYNC_API = "SYNC_API",
    RUNA_API = "RUNA_API",
    RUNA_ORDER_SERVICE = "RUNA_ORDER_SERVICE",
    INTERNAL_SERVICE_API = "INTERNAL_SERVICE_API",
    TEST_TOOL = "TEST_TOOL",
  }
}
