<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  image: string;
}>();

const imageUrl = computed(
  () => new URL(`../../../assets/images/${props.image}`, import.meta.url).href
);
</script>
<template>
  <img :src="imageUrl" class="hidden" />
</template>
