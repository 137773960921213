/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Format - NAME = 'value', (display_name)
 */
export enum UserRoleEnum {
  OWNER = "owner",
  ADMIN = "admin",
  SERVICE_AGENT = "service_agent",
  VIEWER = "viewer",
  FINANCE_MANAGER = "finance_manager",
  SERVICE_MANAGER = "service_manager",
}
