import nProgress from "nprogress";
import "@two-ui/styles/nprogress.sass";
import router from "@two-ui/router";

nProgress.configure({ trickleSpeed: 200, showSpinner: false });

let nProgressDisplayTimer: ReturnType<typeof setTimeout> | undefined;

// Number of milliseconds to wait before displaying the progress bar.
// Intended so it only shows on slow page loads.
const MINIMUM_ROUTE_DELAY = 150;

router.beforeEach((_to, _from, next) => {
  nProgressDisplayTimer = setTimeout(
    () => nProgress.start(),
    MINIMUM_ROUTE_DELAY
  );
  next(); // Must be called for route to continue.
});

router.afterEach(() => {
  if (nProgressDisplayTimer !== undefined) {
    clearTimeout(nProgressDisplayTimer);
    nProgressDisplayTimer = undefined;
  }
  nProgress.done();
});
