<script lang="ts" setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Router } from "vue-router";

type BreadcrumbItem = {
  label: string;
  routerName?: string;
  active?: boolean;
  externalLink?: string;
};

const props = defineProps<{
  items: Array<BreadcrumbItem>;
  router?: Router;
}>();

const handleLinkClick = (item: BreadcrumbItem) => {
  if (!item.externalLink && item.routerName) {
    props.router?.push({ name: item.routerName });
  }
};
</script>

<template>
  <nav aria-label="breadcrumb">
    <ol class="flex items-center">
      <li
        :key="index"
        class="text-sm"
        v-for="(item, index) in items"
        :class="{ active: index === items.length - 1 }"
      >
        <slot name="separator">
          <font-awesome-icon
            :icon="['far', 'angle-right']"
            size="lg"
            v-if="index > 0"
            class="mx-4"
            :style="{ color: 'black' }"
          />
        </slot>
        <slot :item="item" v-if="index < 2 || index >= items.length - 2">
          <span
            class="font-bold text-black"
            v-if="index === 1 && items.length > 3"
          >
            ...
          </span>
          <a
            v-else-if="index !== items.length - 1"
            :href="item.externalLink || 'javascript:void(0)'"
            class="cursor-pointer text-grey-600 no-underline underline-offset-2 hover:underline"
            @click="handleLinkClick(item)"
          >
            <slot name="rootIcon" class="mr-2" v-if="index === 0"></slot>
            {{ item.label }}
          </a>
          <span v-else class="font-bold text-black">{{ item.label }}</span>
        </slot>
      </li>
    </ol>
  </nav>
</template>
