<script setup lang="ts">
const { tag } = withDefaults(
  defineProps<{
    type?:
      | "text-sm"
      | "text-base"
      | "text-lg"
      | "text-xl"
      | "text-2xl"
      | "text-3xl";
    tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "div";
  }>(),
  {
    type: "text-sm",
    tag: "span",
  }
);
</script>

<template>
  <component :is="tag" :class="type"><slot></slot></component>
</template>
