<script setup lang="ts">
import { doLogout } from "@two-ui/auth0";
import { trackEvent } from "@two-ui/mixpanel";
import { onMounted } from "vue";
import FullPageLoader from "@two-ui/components/FullPageLoader.vue";
import { removeHubspotMessages } from "@two-ui/hubspot";

trackEvent("Auth: Log out");

// Remove the hubspot messages button on this as spinner page
removeHubspotMessages();

onMounted(() => {
  doLogout();
});
</script>

<template>
  <FullPageLoader>Logging you out…</FullPageLoader>
</template>
