<template>
  <div>
    <FieldLabel
      v-if="label"
      class="mb-2"
      :label="label"
      :show-optional-hint="showOptionalHint"
      :for="id"
      :description="description"
    />
    <FieldGradientBorderWrapper
      :show-error="
        showRequiredValidation || showCustomValidation || showErrorWithoutText
      "
    >
      <div
        class="field-inner-border field-padding field-background-light flex focus-within:outline-none"
      >
        <FieldPrefix v-if="$slots.prefix">
          <slot name="prefix" />
        </FieldPrefix>
        <input
          @blur="touched = true"
          class="ml-2 w-full field-placeholder field-text-light field-background-light outline-none {{inputNumberClass}} {{ hideSpinButtons }}"
          :class="{
            [hideSpinButtons]: hideSpinButtons,
            [inputNumberClass]: inputNumberClass,
          }"
          :id="id"
          type="number"
          @change="onChange"
          @wheel="onChange"
          @input="onInput"
          :value="formattedValueFormatted"
          :placeholder="placeholder"
          :min="min"
          :max="max"
          :name="name"
        />
        <span class="ml-1 mr-3 whitespace-nowrap" v-if="suffixText">{{
          suffixText
        }}</span>
      </div>
    </FieldGradientBorderWrapper>

    <!-- Error texts -->
    <div>
      <p class="field-error-text" v-if="showRequiredValidation">
        This field is required.
      </p>
      <p class="field-error-text" v-else-if="showCustomValidation">
        {{ customError }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { v4 as uuidv4 } from "uuid";
import FieldGradientBorderWrapper from "./FieldGradientBorderWrapper.vue";
import FieldLabel from "./FieldLabel.vue";
import FieldPrefix from "./FieldPrefix.vue";
import FieldSuffix from "./FieldSuffix.vue";
import { preserveFractionalZeros } from "@two-ui/util/number";

export default defineComponent({
  components: {
    FieldGradientBorderWrapper,
    FieldPrefix,
    FieldLabel,
  },
  emits: ["update:modelValue", "change"],
  props: {
    label: {
      type: String,
    },
    /**
     * Description to show below the field label
     */
    description: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    modelValue: {
      type: Number,
    },
    /**
     * To show text indicating field is required below the field after touched
     */
    required: {
      type: Boolean,
      default: false,
    },
    allowDecimal: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    name: {
      type: String,
    },
    //TODO: not working correctly, rendering currency at wrong place. Also, name should be generic as we might want to add any character
    currency: {
      type: String,
    },
    /**
     * For adding additional classes to numbers input element
     */
    inputNumberClass: { type: String, default: "" },
    /**
     * If you want the field to update on keypress
     */
    updateOnInput: { type: Boolean, default: false },
    /**
     * Show any custom error text below the field.
     * Will show required text first is required prop is set and field is empty.
     */
    customError: { type: String },
    /**
     * Used to indicate in label that field is optional
     */
    showOptionalHint: { type: Boolean, default: false },
    suffixText: { type: String },
    showErrorWithoutText: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const id = uuidv4();
    const touched = ref(false);
    const formattedValueFormatted = computed(() => {
      if (props.modelValue && props.currency) {
        return preserveFractionalZeros(props.modelValue, props.currency);
      }
      return props.modelValue;
    });

    const showRequiredValidation = computed(
      () =>
        touched.value &&
        props.required &&
        (props.modelValue === null || props.modelValue === undefined)
    );
    const showCustomValidation = computed(
      () => touched.value && !!props.customError
    );
    const hideSpinButtons = props.suffixText ? "hide-spin-button" : "";
    const emitValue = (value?: number) => {
      emit("update:modelValue", value);
      emit("change", value);
    };

    onMounted(() => {
      if (props.modelValue) {
        emitValue(props.modelValue);
      }
    });
    const onChange = (event: Event) => {
      const eventTarget = event.target as HTMLInputElement;
      const { value: inputValue } = eventTarget;
      const inputValueNumber = Number(inputValue);
      let modifiedInputValue: number | undefined;
      if (inputValue) {
        if (props.max && inputValueNumber > props.max)
          modifiedInputValue = props.max;
        if (props.min && inputValueNumber < props.min)
          modifiedInputValue = props.min;
        if (!props.allowDecimal && inputValue.includes("."))
          modifiedInputValue = Math.floor(
            modifiedInputValue || inputValueNumber
          );
      }
      if (inputValue) {
        emitValue(modifiedInputValue || inputValueNumber);
      }
      // if input is empty, emit undefined to trigger required validation
      else {
        emitValue();
      }
      // update input element value if modified due to min/max constraint
      if (modifiedInputValue) {
        eventTarget.value = modifiedInputValue?.toString() || "";
      }
    };
    const onInput = (event: Event) => {
      if (props.updateOnInput) {
        onChange(event);
      }
    };
    return {
      id,
      touched,
      showRequiredValidation,
      showCustomValidation,
      onChange,
      onInput,
      hideSpinButtons,
      formattedValueFormatted,
    };
  },
});
</script>
<style lang="sass" scoped>
@import "../styles/field"
</style>
<style>
/* Chrome, Safari, Edge, Opera */
input.hide-spin-button::-webkit-outer-spin-button,
input.hide-spin-button::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"].hide-spin-button {
  -moz-appearance: textfield;
}
</style>
