import { getLocale, getTimezone } from "@two-ui/util/l10n";
import { DateTime, DateTimeOptions } from "luxon";

/**
 * Format the date based on the user's locale.
 *
 * @param {string} date - If the date string does not contain a timezone offset e.g.,
 *    `+00:00` or `Z`, then it's assumed the date/time is UTC.
 * @param {string | undefined} options.timeZone - The timezone to use in the return
 *    value. If `undefined`, the user's local timezone or UTC will be used depending on
 *    their user settings.
 */
export function formatDate(
  date: string,
  options: Intl.DateTimeFormatOptions = {
    timeZone: undefined,
  }
) {
  date = appendUtcOffsetIfHasNoOffset(date)!;

  // Avoid changing the supplied options object.
  let finalOptions = { ...options };

  if (!finalOptions.dateStyle) {
    finalOptions.dateStyle = "medium";
  }

  if (!finalOptions.timeZone) {
    finalOptions.timeZone = getTimezone();
  }

  return new Intl.DateTimeFormat(getLocale(), finalOptions).format(
    new Date(date)
  );
}

/**
 * Format the date and time based on the user's locale.
 *
 * @param {string} date - If the date/time string does not contain a timezone offset,
 *    e.g., `+00:00` or `Z`, then it's assumed the date/time is UTC.
 * @param {string | undefined} options.timeZone - The timezone to use in the return
 *    value. If `undefined`, the user's local timezone or UTC will be used depending on
 *    their user settings.
 */
export function formatDateTime(
  date: string,
  options: Intl.DateTimeFormatOptions = {
    timeZone: undefined,
  }
) {
  date = appendUtcOffsetIfHasNoOffset(date)!;

  // Avoid changing the supplied options object.
  let finalOptions = { ...options };

  if (!finalOptions.dateStyle) {
    finalOptions.dateStyle = "medium";
  }

  if (!finalOptions.timeStyle) {
    finalOptions.timeStyle = "short";
  }

  if (!finalOptions.timeZone) {
    finalOptions.timeZone = getTimezone();
  }

  return new Intl.DateTimeFormat(getLocale(), finalOptions).format(
    new Date(date)
  );
}
/**
 * Get formatted minutes for given milliseconds
 * @returns e.g. 01:30 for 90000 milliseconds
 */
export function getMinutesFormatted(milliseconds: number) {
  return new Date(milliseconds).toISOString().substr(14, 5);
}

/**
 * Converts the provided date and time into their locale-specific forms, returning each
 * separately.
 * @param {string | undefined} options.timeZone - The timezone to use in the return
 *    value. If `undefined`, the user's local timezone or UTC will be used depending on
 *    their user settings.
 * @returns e.g. [02 May 2023, 03:22 PM] for 2023-05-02T15:22:22.654071+00:00
 */
export function getFormattedDateTimeParts(
  inputDateTime: string | undefined,
  options: Intl.DateTimeFormatOptions = {
    timeZone: undefined,
  }
): [string, string] {
  inputDateTime = appendUtcOffsetIfHasNoOffset(inputDateTime);

  // Avoid changing the supplied options object.
  let finalOptions = { ...options };

  if (!finalOptions.timeZone) {
    finalOptions.timeZone = getTimezone();
  }

  if (inputDateTime == undefined || inputDateTime == "") {
    return ["Date unavailable", "Time unavailable"];
  }

  const locale = getLocale();
  const dateTime = new Date(inputDateTime);
  const formattedDate = dateTime.toLocaleString(locale, {
    day: "numeric",
    month: "short",
    year: "numeric",
    ...finalOptions,
  });
  const formattedTime = dateTime.toLocaleString(locale, {
    hour: "numeric",
    minute: "2-digit",
    ...finalOptions,
  });

  return [formattedDate, formattedTime];
}

/**
 * Like formatDateTime, but wraps some HTML around the time to place it on a separate
 * line.
 *
 * @param {string | undefined} options.timeZone - The timezone to use in the return
 *    value. If `undefined`, the user's local timezone or UTC will be used depending on
 *    their user settings.
 */
export function multilineDateAndTime(
  date: string,
  options: Intl.DateTimeFormatOptions = {
    timeZone: undefined,
  }
): string {
  const dateTimeParts = getFormattedDateTimeParts(date, options);
  return `${dateTimeParts[0]} <br /> <span class="text-grey-400">${dateTimeParts[1]} </span> `;
}

/**
 * Transform a start and end date from this format: { 2023-05-01T15:22:22.654071+00:00, 2023-05-31T15:22:22.654071+00:00 } to a readable format
 * @returns e.g. 1 - 31 May 2023 for [ 2023-05-01T15:22:22.654071+00:00, 2023-05-31T15:22:22.654071+00:00 ]
 * @returns e.g. 01 May - 01 June 2023 for [ 2023-05-01T15:22:22.654071+00:00, 2023-06-01T15:22:22.654071+00:00 ]
 * @returns e.g. 31 May 2023 for [ 2023-05-31T15:22:22.654071+00:00, 2023-05-31T15:22:22.654071+00:00 ]
 */
export function formatDateRange(
  startDate: string | undefined,
  endDate: string | undefined,
  short: boolean = false,
  timeZone: string | undefined = undefined
): string {
  if ((startDate || endDate) == undefined || (startDate || endDate) == "") {
    return `Date unavailable, Time unavailable`;
  }

  startDate = appendUtcOffsetIfHasNoOffset(startDate);
  endDate = appendUtcOffsetIfHasNoOffset(endDate);

  let dateOptions: Intl.DateTimeFormatOptions = {};

  if (!timeZone) {
    timeZone = getTimezone();
  }

  dateOptions.day = "numeric";

  if (short) {
    dateOptions.month = "short";
  } else {
    dateOptions.month = "long";
  }

  const locale = getLocale();

  const startDateObj = DateTime.fromISO(startDate!, {
    locale: locale,
    zone: timeZone,
  });
  const startYearString = startDateObj.toLocaleString({ year: "numeric" });
  const startMonthDayString = startDateObj.toLocaleString(dateOptions);

  const endDateObj = DateTime.fromISO(endDate!, {
    locale: locale,
    zone: timeZone,
  });
  const endYearString = endDateObj.toLocaleString({ year: "numeric" });
  const endMonthDayString = endDateObj.toLocaleString(dateOptions);

  let formattedDateRange = "";

  if (startYearString === endYearString) {
    if (startMonthDayString === endMonthDayString) {
      formattedDateRange = `${startMonthDayString} ${startYearString}`;
    } else {
      formattedDateRange = `${startMonthDayString} - ${endMonthDayString} ${startYearString}`;
    }
  } else {
    formattedDateRange = `${startMonthDayString} ${startYearString} - ${endMonthDayString} ${endYearString}`;
  }

  return formattedDateRange;
}

/**
 * Appends the UTC timezone offset to the end of a datetime string, if it doesn't
 * already have one. For example, if the provided date is `2024-07-01T13:00:00`, this
 * function will return `2024-07-01T13:00:00+00:00`.
 *
 * @param datetime The datetime to augment.
 */
function appendUtcOffsetIfHasNoOffset(
  datetime: string | undefined
): string | undefined {
  if (datetime && datetime.match(/:/) && !datetime.match(/[+Z]/)) {
    return `${datetime}+00:00`;
  }
  return datetime;
}
