import { VALID_EMAIL_REGEX, VALID_URL_REGEX } from "@two-ui/constants";

export function pluralise(count: number, singular: string, plural: string) {
  return count === 1 ? singular : plural;
}

export function insertCharsBetweenEveryNChars(
  targetStr: string,
  charsToInsert: string,
  n: number
): string {
  const regex = new RegExp(`.{1,${n}}`, "g");
  const nSizedSubStrings = targetStr.match(regex);
  return nSizedSubStrings ? nSizedSubStrings.join(charsToInsert) : targetStr;
}

// Render a joined list of strings truncated to n, if there are more some text is added at the end
// e.g. "john.smith@example.com, jane.smith@exmaple.com, first.name@example.com & 4 others"
export function showNItemsAndMore(items: string[], n: number): string {
  if (items.length <= n) {
    return items.join(", ");
  }
  const truncatedItemCount = items.length - n;
  return `${items.slice(0, n).join(", ")} & ${truncatedItemCount} ${pluralise(
    truncatedItemCount,
    "other",
    "others"
  )}`;
}

export function getDomainFromEmail(email: string) {
  const parts = email.split("@");
  if (parts.length === 1) return "";
  return parts.pop() || "";
}

/**
 * Get initials for full name by concatenating first and last name's initials
 * @param fullName
 * @returns e.g. TU for "Test User"
 */
export function getInitialsFromFullName(fullName: string) {
  const nameParts = fullName.split(" ");
  const namePartsInitials = nameParts.map((part) => part[0] || "");
  if (nameParts.length === 1) {
    return namePartsInitials[0].toUpperCase();
  }
  // concatenate first and last initial
  return (
    namePartsInitials[0] + namePartsInitials[nameParts.length - 1]
  ).toUpperCase();
}

/**
 * Validates if value is valid email
 * @param value
 * @returns boolean
 */
export function isValidEmail(value: string) {
  return VALID_EMAIL_REGEX.test(value);
}

/**
 * Validates if value is valid url
 * @param value
 * @returns boolean
 */
export function isValidUrl(value: string) {
  return VALID_URL_REGEX.test(value);
}

export function splitStringByMultipleDelimiters(
  str: string,
  delimiters: string[]
) {
  return str.split(new RegExp(`[${delimiters.join("")}]`, "g"));
}

/**
 * Validates if value is valid hex color e.g. #FFFFFF
 * @param value
 * @returns boolean
 */
export function isValidHexColour(str: string) {
  return new RegExp(`^#([0-9A-F]{3}){1,2}$`, "i").test(str);
}

// Try and guess the initials of a person from their email address
// This is not meant for anything serious, just to do some pretty UI bits
export const getInitialsFromEmail = (email: string): string => {
  const usernameParts = email
    .split("@")[0] // Split the email address at the @ symbol and take the username
    .split(".") // Split on dots, company email usernames are often like 'fred.bloggs'
    .filter((part) => part); // Filter out empty parts
  if (usernameParts.length > 1) {
    const initials = usernameParts.map(
      (username) => username[0].toUpperCase() // Take the first letter of each 'word' in username and uppercase
    );
    return `${initials[0]}${initials.at(-1)}`; // Concatenate the initials
  } else if (usernameParts.length === 1) {
    // If we can't work out parts just take the first letter of the username
    return usernameParts[0].substring(0, 1).toUpperCase();
  } else {
    return "";
  }
};

/**
 * Encode a string to base64
 * @param str
 * @returns Base64 string
 */
export const toBase64 = (str: string): string => {
  try {
    return btoa(str);
  } catch (err) {
    // @ts-ignore
    return Buffer.from(str).toString("base64");
  }
};
