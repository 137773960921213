// Compute the base URL of the site you're on
// You probably should just use BASE_URL from @two-ui/constants
export const getBaseUrl = (): string => {
  if (location.origin) {
    // All modern browsers support this
    return location.origin;
  } else if (import.meta.env.BASE_URL !== "/") {
    // but if they don't fall-back to build time base url, remove trailing slash if present
    return import.meta.env.BASE_URL.endsWith("/")
      ? import.meta.env.BASE_URL.slice(0, -1)
      : import.meta.env.BASE_URL;
  } else {
    // and if everything else fails, fall-back to localhost as we're probably testing
    return "http://localhost:3000";
  }
};
