<script setup lang="ts">
const beforeEnter = (el: Element) => {
  (el as HTMLElement).style.height = "0";
};
const enter = (el: Element) => {
  (el as HTMLElement).style.height = el.scrollHeight + "px";
};
const afterEnter = (el: Element) => {
  // unset fixed height so that height is changed automatically on content change
  (el as HTMLElement).style.height = "unset";
};
const beforeLeave = (el: Element) => {
  (el as HTMLElement).style.height = el.scrollHeight + "px";
};
const leave = (el: Element) => {
  (el as HTMLElement).style.height = "0";
};
</script>
<template>
  <transition
    name="accordion"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
  >
    <slot />
  </transition>
</template>
