// This beacon is loaded only once in the app.

import { importScript } from "./util/script-loader";
import { v4 as uuidv4 } from "uuid";
import { RISKIFIED_SESSION_ID_KEY as key } from "./constants";

// The session_id is sent to scion in /card-payment/braintree api call while making payment
export async function loadRiskifiedBeacon() {
  const store_domain = import.meta.env.VITE_RISKIFIED_ACCOUNT;

  let session_id = localStorage.getItem(key);
  if (!session_id) {
    session_id = uuidv4();
    localStorage.setItem(key, session_id);
  }

  importScript(
    `https://beacon.riskified.com/?shop=${store_domain}&sid=${session_id}`
  );
}
