<script lang="ts" setup>
import { SidebarProvider } from "../../components/types";
import { computed, provide, ref, useSlots } from "vue";
import {
  Router,
  RouteLocationRaw,
  RouteLocationNormalizedLoaded,
} from "vue-router";

const slots = useSlots();

const props = defineProps<{
  /**
   * Vue Router's router instance
   */
  router: Router;
  /**
   * Vue Router's current route location
   */
  route: RouteLocationNormalizedLoaded;
}>();

const selectedRouteName = computed(() => props.route.name);

provide<SidebarProvider>("sidebarProvider", {
  router: props.router,
  selectedRouteName,
});
</script>
<template>
  <div
    class="sticky top-0 flex h-screen flex-col overflow-y-auto bg-black text-grey-100"
  >
    <header v-if="slots.header" class="px-6">
      <slot name="header" />
    </header>
    <nav class="flex-1">
      <ul>
        <slot />
      </ul>
    </nav>
    <footer v-if="slots.footer" class="px-6">
      <slot name="footer" />
    </footer>
  </div>
</template>
