<script setup lang="ts">
import { computed } from "vue";

type Props = {
  currentPage: number | 0;
  totalItems: number | 0;
  itemsPerPage: number;
  labelItems: string;
  cursorBased: boolean | false;
  pageLimits: Array<number> | [20, 50];
  nextDisabled: boolean | false;
  prevDisabled: boolean | true;
};
const props = defineProps<Props>();

//Emits and handlers
const emit = defineEmits([
  "updatePage",
  "previousPage",
  "nextPage",
  "updateItemsPerPage",
]);
const handlePageClick = (page: number | string) => {
  if (page !== ELLIPSIS) {
    emit("updatePage", page);
  }
};

const previousPage = () => {
  emit("previousPage");
};

const nextPage = () => {
  emit("nextPage");
};

const handleItemsPerPageUpdate = (selectedValue: Event) => {
  const selectedValueData = selectedValue.target as HTMLInputElement;
  emit("updateItemsPerPage", parseInt(selectedValueData.value));
};

//State and constants
const ELLIPSIS = "...";
const itemsPerPageOptions = props.pageLimits;
const totalPages = computed(() =>
  Math.ceil(props.totalItems / props.itemsPerPage)
);

const shouldAddPageNumber = (pageNumber: number) => {
  return (
    pageNumber === 1 ||
    pageNumber === totalPages.value ||
    (pageNumber >= props.currentPage - 2 &&
      pageNumber <= props.currentPage + 3) ||
    (props.currentPage <= 5 && pageNumber <= 6)
  );
};

const shouldAddEllipsis = (pageNumber: number) => {
  return (
    (pageNumber === 2 && props.currentPage > 5) ||
    (pageNumber === totalPages.value - 1 &&
      props.currentPage < totalPages.value - 4)
  );
};

const paginationArray = computed(() => {
  const array = [];
  for (let i = 1; i <= totalPages.value; i++) {
    if (shouldAddPageNumber(i)) {
      array.push(i);
    } else if (shouldAddEllipsis(i)) {
      array.push(ELLIPSIS);
    }
  }

  return array;
});
</script>

<template>
  <div
    data-testid="pagination-menu"
    class="text-md mx-auto flex w-full items-center justify-between gap-2 py-3 sm:flex-row"
  >
    <!-- Pagination Buttons -->
    <div class="flex items-center gap-2 overflow-x-auto">
      <div v-if="cursorBased" class="flex">
        <button
          class="pagination-cursor hover:purple cursor-pointer px-3.5 py-2"
          @click="previousPage()"
          :disabled="props.prevDisabled == true"
        >
          <font-awesome-icon :icon="['far', 'chevron-left']" />
        </button>
        <button
          class="pagination-cursor ml-1 cursor-pointer px-3.5 py-2"
          @click="nextPage"
          :disabled="props.nextDisabled == true"
        >
          <font-awesome-icon :icon="['far', 'chevron-right']" />
        </button>
      </div>
      <ul
        v-else
        v-for="(page, index) in paginationArray"
        :key="page === ELLIPSIS ? `ellipsis-${index}` : `page-${page}`"
        class="pagination-button px-2 py-0.5"
        :class="{ active: currentPage === page }"
        @click="handlePageClick(page)"
      >
        <li data-testid="pagination-page-option">{{ page }}</li>
      </ul>
    </div>

    <!-- Page Information -->
    <div
      class="flex items-center gap-1 text-center"
      data-testid="pagination-informaton"
    >
      <span>Showing </span>
      <select
        data-testid="pagination-items-per-page-dropdown"
        :value="props.itemsPerPage"
        class="dropdown"
        @change="handleItemsPerPageUpdate"
      >
        <option
          data-testid="pagination-items-per-page-dropdown-option"
          v-for="option in itemsPerPageOptions"
          :key="option"
          :value="option"
        >
          {{ option }}
        </option>
      </select>
      <span v-if="!cursorBased">
        of <strong>{{ totalItems }} </strong></span
      >
      &nbsp;{{ props.labelItems }}
    </div>
  </div>
</template>

<style lang="sass" scoped>
.pagination-button
  border-radius: 5px
  cursor: pointer

.pagination-button.active
  background-color: black
  color: white

.pagination-cursor
  border-radius: 5px
  cursor: pointer

  @apply disabled:opacity-50 disabled:pointer-events-none

  &:hover
    background: rgba(151,71,255, .1)
    color: #505BF0
</style>
