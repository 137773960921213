<script setup lang="ts">
import { onMounted } from "vue";
import FullPageLoader from "@two-ui/components/FullPageLoader.vue";
import { useRoute } from "vue-router";
import { doLogin } from "@two-ui/auth0";
const {
  query: { email: email_address },
} = useRoute();

onMounted(() => {
  if (typeof email_address === "string") {
    // redirect to auth0 signup, passing email from the token.
    doLogin({ screen_hint: "signup", login_hint: email_address });
  } else {
    doLogin({ screen_hint: "signup" });
  }
});
</script>

<template>
  <FullPageLoader>Please wait…</FullPageLoader>
</template>
