import { DirectiveBinding } from "vue";
import {
  GuardAction,
  PermissionDirectiveBindingValue,
  UserPermissions,
} from "../types";

import { useGlobalStore } from "@two-ui/global-store";

const permissionDirective = {
  mounted(
    el: HTMLElement,
    binding: DirectiveBinding<PermissionDirectiveBindingValue>
  ) {
    if (
      !hasPermission(
        binding.value.permissions || [],
        binding.value.guardAction || GuardAction.AND
      )
    ) {
      if (binding.value.disableButtons) {
        let buttonElements = el.getElementsByTagName("button");
        for (let i = 0; i < buttonElements.length; i++) {
          buttonElements[i].toggleAttribute("disabled", true);
        }
        el.toggleAttribute("disabled", true);
      } else {
        el.style.display = "none";
      }
    }
  },
};

export function hasPermission(
  permissions: UserPermissions[],
  guardAction: GuardAction = GuardAction.AND
) {
  const globalStore = useGlobalStore();
  return guardAction === GuardAction.AND
    ? permissions.every((p) => globalStore.userPermissions?.includes(p))
    : permissions.some((p) => globalStore.userPermissions?.includes(p));
}
export default permissionDirective;
