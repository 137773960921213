<script setup lang="ts">
import { useRouter } from "vue-router";
import TwoButton from "./TwoButton.vue";
import { computed, ref } from "vue";

const router = window.globalRouter || useRouter();
const CLOUDFLARE_IMAGE_URL = import.meta.env.VITE_CLOUDFLARE_IMAGE_URL;
const backgroundImageUrl = `${CLOUDFLARE_IMAGE_URL}/62388daa-d2be-450f-69d6-a677d27c1100/public`;
const isBannerOpen = ref(true);
const emit = defineEmits<{ (e: "click"): void }>();

const requestAccessToBrands = async () => {
  emit("click");
};
const props = defineProps<{
  header: string;
  subtitle?: string;
  buttonText: string;
}>();

const bannerIcon = computed(() =>
  isBannerOpen.value ? "chevron-up" : "chevron-down"
);

const toggleBanner = () => {
  isBannerOpen.value = !isBannerOpen.value;
};
</script>

<template>
  <main
    class="main-container-backgorund max-w-screen-2xl rounded-2xl bg-black"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundColor: 'black',
    }"
  >
    <section class="relative overflow-hidden rounded-2xl" data-testid="banner">
      <div class="flex justify-between">
        <div
          :class="isBannerOpen ? 'p-8' : 'ml-8 py-3'"
          class="flex flex-col justify-center gap-2 rounded-3xl text-white"
        >
          <h1
            class="font-850 m-0 text-left font-heading text-xl leading-7 text-white"
          >
            {{ props.header }}
          </h1>

          <div
            class="text-base font-normal leading-normal text-white"
            v-show="isBannerOpen"
          >
            {{ props.subtitle }}
          </div>
        </div>
        <div class="flex">
          <div
            v-show="isBannerOpen"
            class="flex items-center whitespace-nowrap"
          >
            <TwoButton
              data-testid="banner-button"
              class="btn-gradient my-4 mb-2 text-black"
              @click="requestAccessToBrands"
              :disabled="false"
            >
              {{ props.buttonText }}
            </TwoButton>
          </div>

          <font-awesome-icon
            id="collapseBanner"
            @click="toggleBanner"
            :icon="['far', bannerIcon]"
            class="pr-4 pt-4 text-white"
          />
        </div>
      </div>
    </section>
  </main>
</template>

<style></style>
