import { datadogRum } from "@datadog/browser-rum";

// Only include RUM on production and sandbox environments, exclude on development
const DATADOG_INCLUDED_MODES = ["production", "sandbox"];

const datadogInitRum = () =>
  datadogRum.init({
    applicationId: "0e584ee3-4fb0-44d4-bc4a-85d4a3f56722",
    clientToken: "pubb6c7faab5da77bcabc8347f252faf7bc",
    site: "datadoghq.com",
    service: "two-ui",
    env: import.meta.env.MODE,
    // Specify a version number to identify the deployed version of your application in Datadog
    // TODO: We don't have version numbers for the frontend, perhaps look into using commit hashes
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
  });

if (DATADOG_INCLUDED_MODES.includes(import.meta.env.MODE)) datadogInitRum();
