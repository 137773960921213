import { useGlobalStore } from "@two-ui/global-store";

/**
 * Gets the user's current locale.
 */
export function getLocale(): string {
  return navigator.language;
}

/**
 * Gets the user's timezone (or timezone override from the settings page) offset in
 * ±HH:MM format, prefixed with UTC. For example, UTC+01:00.
 */
export function getTimezoneOffset(): string | undefined {
  let offset = (
    new Intl.DateTimeFormat(getLocale(), {
      timeZoneName: "longOffset",
      timeZone: getTimezone(),
    })
      .formatToParts()
      .find((el) => el.type === "timeZoneName") || {}
  ).value;

  if (offset && offset == "GMT") {
    offset = "UTC+00:00";
  }

  return offset?.replace("GMT", "UTC");
}

/**
 * Gets the user's timezone (or timezone override from the settings page). For example,
 * `Europe/London`, `Etc/UTC`, etc...
 */
export function getTimezone(): string {
  const globalStore = useGlobalStore();
  if (globalStore.timezoneOverride) {
    return globalStore.timezoneOverride;
  }
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
