<script lang="ts" setup>
import { useRouter } from "vue-router";
import { computed } from "vue";
import store from "@two-ui/store";
import { TwoGradientBorderButton } from "@wegift/two-components";

import { trackEvent } from "@two-ui/mixpanel";

const router = useRouter();
const createOrder = () => {
  trackEvent("Order: Clicked to create order");
  router.push({ name: "order-type-selection" });
};

const buttonDisabled = computed(
  () => !!store.state.customerErr || !store.state.customerId
);
</script>

<template>
  <TwoGradientBorderButton
    class="focus-within:!ring-white"
    name="create-new-reward"
    @click="createOrder"
    :buttonProps="{ disabled: buttonDisabled }"
  >
    <font-awesome-icon :icon="['far', 'plus']" class="mr-1"></font-awesome-icon>
    Create order
  </TwoGradientBorderButton>
</template>
