<script setup lang="ts">
import { onMounted } from "vue";
import FullPageLoader from "@two-ui/components/FullPageLoader.vue";
import { doLogin } from "@two-ui/auth0";
import { storeCustomerOnboardingFlag } from "@two-ui/services/onboarding";

onMounted(() => {
  storeCustomerOnboardingFlag();
  doLogin({ screen_hint: "signup" });
});
</script>

<template>
  <FullPageLoader>Please wait…</FullPageLoader>
</template>
