/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApprovePendingCustomerResponse } from "../models/ApprovePendingCustomerResponse";
import type { DeclinePendingCustomerResponse } from "../models/DeclinePendingCustomerResponse";
import type { OnboardingStateResponse } from "../models/OnboardingStateResponse";
import type { PendingCustomer } from "../models/PendingCustomer";
import type { PendingCustomerDecisionRequest } from "../models/PendingCustomerDecisionRequest";
import type { PendingCustomerRequest } from "../models/PendingCustomerRequest";
import type { PendingCustomersResponse } from "../models/PendingCustomersResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class PendingCustomerService {
  /**
   * Get pending customers by status
   * @param status
   * @param customerNameBeginsWith
   * @param limit
   * @param cursor
   * @returns PendingCustomersResponse OK
   * @throws ApiError
   */
  public static getPendingCustomers(
    status: "NEW" | "APPROVED" | "PENDING" | "DECLINED",
    customerNameBeginsWith?: string,
    limit?: number,
    cursor?: string
  ): CancelablePromise<PendingCustomersResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/pending-customer",
      query: {
        status: status,
        customer_name_begins_with: customerNameBeginsWith,
        limit: limit,
        cursor: cursor,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Create a pending customer
   * @param requestBody
   * @returns PendingCustomer OK
   * @throws ApiError
   */
  public static createPendingCustomer(
    requestBody: PendingCustomerRequest
  ): CancelablePromise<PendingCustomer> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/pending-customer",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `ValidationErrorResponse`,
      },
    });
  }

  /**
   * Approve a pending customer
   * @param requestBody
   * @returns ApprovePendingCustomerResponse OK
   * @throws ApiError
   */
  public static approvePendingCustomer(
    requestBody: PendingCustomerDecisionRequest
  ): CancelablePromise<ApprovePendingCustomerResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/pending-customer/approve",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `ValidationErrorResponse`,
      },
    });
  }

  /**
   * Decline a pending customer
   * @param requestBody
   * @returns DeclinePendingCustomerResponse OK
   * @throws ApiError
   */
  public static declinePendingCustomer(
    requestBody: PendingCustomerDecisionRequest
  ): CancelablePromise<DeclinePendingCustomerResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/pending-customer/decline",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `ValidationErrorResponse`,
      },
    });
  }

  /**
   * Get customer creation state
   * @returns OnboardingStateResponse OK
   * @throws ApiError
   */
  public static getOnboardingState(): CancelablePromise<OnboardingStateResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/onboarding-state",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `No customers found`,
      },
    });
  }
}
