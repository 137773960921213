import { OrderHistoryState } from "@two-ui/sub-stores/types";
import {
  DropdownSelectOptions,
  SelectedDateRangeFilter,
  SelectedValueRangeFilter,
} from "@workspace/packages/two-components/src/components/types";
import { reactive, readonly } from "vue";
import { captureException } from "../sentry";

const defaultState: OrderHistoryState = {
  ordersHistorySearchText: null,
  ordersHistoryDateRange: null,
  payoutsSentSearchText: null,
  payoutsSentDateRange: null,
  products: null,
  orderStatus: null,
  valueRange: null,
};
const state = reactive<OrderHistoryState>({ ...defaultState });
const storageValuesKeysPrefix = "order-history-";

const orderHistoryStore = {
  state: readonly(state),

  setOrdersHistorySearchText(searchText: string) {
    state.ordersHistorySearchText = searchText;
    localStorage.setItem(
      `${storageValuesKeysPrefix}orders-history-search-text`,
      searchText
    );
  },
  setPayoutsSearchText(searchText: string) {
    state.payoutsSentSearchText = searchText;
    localStorage.setItem(
      `${storageValuesKeysPrefix}payouts-sent-search-text`,
      searchText
    );
  },
  setOrdersHistoryDateRange(dateRange: SelectedDateRangeFilter) {
    state.ordersHistoryDateRange = dateRange;
    localStorage.setItem(
      `${storageValuesKeysPrefix}orders-history-date-range`,
      JSON.stringify(dateRange)
    );
  },
  setPayoutsSentDateRange(dateRange: SelectedDateRangeFilter) {
    state.payoutsSentDateRange = dateRange;
    localStorage.setItem(
      `${storageValuesKeysPrefix}payouts-sent-date-range`,
      JSON.stringify(dateRange)
    );
  },
  setProducts(products: DropdownSelectOptions[]) {
    state.products = products;
    localStorage.setItem(
      `${storageValuesKeysPrefix}products`,
      JSON.stringify(products)
    );
  },
  setOrderStatus(orderStatus: string) {
    state.orderStatus = orderStatus;
    localStorage.setItem(`${storageValuesKeysPrefix}order-status`, orderStatus);
  },
  setValueRange(valueRange: SelectedValueRangeFilter) {
    state.valueRange = valueRange;
    localStorage.setItem(
      `${storageValuesKeysPrefix}value-range`,
      JSON.stringify(valueRange)
    );
  },
  getOrderHistoryFilters() {
    return {
      orderHistorySearchText: state.ordersHistorySearchText,
      orderHistoryDateRange: state.ordersHistoryDateRange,
      orderStatus: state.orderStatus,
    };
  },
  getPayoutsSentFilters() {
    return {
      payoutsSentSearchText: state.payoutsSentSearchText,
      payoutsSentDateRange: state.payoutsSentDateRange,
      products: state.products,
      valueRange: state.valueRange,
    };
  },
  /**
   * Are any filters applied for orders search, not payout sent search which contains multiple filters
   */
  anyFiltersAppliedForOrderHistoryFiltering() {
    return Object.values(this.getOrderHistoryFilters()).some(
      (filter) => filter !== null
    );
  },
  clearOrderHistorySearchText() {
    state.ordersHistorySearchText = null;
    localStorage.removeItem(
      `${storageValuesKeysPrefix}orders-history-search-text`
    );
  },
  clearPayoutsSentSearchText() {
    state.payoutsSentSearchText = null;
    localStorage.removeItem(
      `${storageValuesKeysPrefix}payouts-sent-search-text`
    );
  },
  clearPayoutsSentDateRange() {
    state.payoutsSentDateRange = null;
    localStorage.removeItem(
      `${storageValuesKeysPrefix}payouts-sent-date-range`
    );
  },
  clearOrdersHistoryDateRange() {
    state.ordersHistoryDateRange = null;
    localStorage.removeItem(
      `${storageValuesKeysPrefix}orders-history-date-range`
    );
  },
  clearProducts() {
    state.products = null;
    localStorage.removeItem(`${storageValuesKeysPrefix}products`);
  },
  clearOrderStatus() {
    state.orderStatus = null;
    localStorage.removeItem(`${storageValuesKeysPrefix}order-status`);
  },
  clearValueRange() {
    state.valueRange = null;
    localStorage.removeItem(`${storageValuesKeysPrefix}value-range`);
  },
  loadFromStorage() {
    try {
      const payoutsSentDateRange = localStorage.getItem(
        `${storageValuesKeysPrefix}payouts-sent-date-range`
      );
      if (payoutsSentDateRange) {
        state.payoutsSentDateRange = JSON.parse(payoutsSentDateRange);
      }
      const ordersHistoryDateRange = localStorage.getItem(
        `${storageValuesKeysPrefix}orders-history-date-range`
      );
      if (ordersHistoryDateRange) {
        state.ordersHistoryDateRange = JSON.parse(ordersHistoryDateRange);
      }
      const ordersHistorySearchText = localStorage.getItem(
        `${storageValuesKeysPrefix}orders-history-search-text`
      );
      if (ordersHistorySearchText) {
        state.ordersHistorySearchText = ordersHistorySearchText;
      }
      const payoutsSentSearchText = localStorage.getItem(
        `${storageValuesKeysPrefix}payouts-sent-search-text`
      );
      if (payoutsSentSearchText) {
        state.payoutsSentSearchText = payoutsSentSearchText;
      }
      const products = localStorage.getItem(
        `${storageValuesKeysPrefix}products`
      );
      if (products) {
        state.products = JSON.parse(products);
      }
      const orderStatus = localStorage.getItem(
        `${storageValuesKeysPrefix}order-status`
      );
      if (orderStatus) {
        state.orderStatus = orderStatus;
      }
      const valueRange = localStorage.getItem(
        `${storageValuesKeysPrefix}value-range`
      );
      if (valueRange) {
        state.valueRange = JSON.parse(valueRange);
      }
    } catch (ex) {
      console.error("Error while reading persisted order history filters", ex);
      captureException(ex);
    }
  },
  clearStorage() {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(storageValuesKeysPrefix)) {
        localStorage.removeItem(key);
      }
    });
  },
};

export default orderHistoryStore;
