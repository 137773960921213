<script setup lang="ts">
import { computed, provide, ref, watch } from "vue";
import { RadioGroupProvider } from "./types";

const props = defineProps({
  modelValue: { type: String },
  name: { type: String, required: true },
});
const emit = defineEmits(["change", "update:modelValue"]);

const modelValueForRadioElements = computed(() => props.modelValue);

const emitEvents = (value: string | number | boolean) => {
  emit("change", value);
  emit("update:modelValue", value);
};

provide<RadioGroupProvider>("radioGroup", {
  emit: emitEvents,
  modelValue: modelValueForRadioElements,
  name: props.name,
});
</script>

<template>
  <div role="radiogroup">
    <slot />
  </div>
</template>
