import { assert } from "./typescript";

// List of scripts we imported in the current session
const importedScripts: {
  //script src
  src: string;
  // script load promise, if fulfilled/rejected, indicates script loaded or errored out.
  scriptLoad: Promise<void>;
}[] = [];

function scriptAlreadyImported(src: string) {
  return importedScripts.some((script) => script.src === src);
}

function addScriptToImportedScripts(script: HTMLScriptElement) {
  importedScripts.push({
    src: script.src,
    scriptLoad: new Promise((resolve, reject) => {
      script.onload = () => resolve();
      script.onerror = () => reject();
    }),
  });
}

function getScriptLoadPromise(src: string) {
  const script = importedScripts.find((script) => script.src === src);
  assert(script, "Script not found in imported scripts");
  return script.scriptLoad;
}

/**
 * Imports script for the give src. Returns promise that resolves when script loads.
 * If script already loaded, resolves immediately
 */
export function importScript(
  src: string,
  async: boolean = true
): Promise<void> {
  if (scriptAlreadyImported(src)) {
    return getScriptLoadPromise(src);
  }
  const script = document.createElement("script");
  script.src = src;
  script.type = "text/javascript";
  script.async = async;
  document.body.appendChild(script);
  addScriptToImportedScripts(script);
  return getScriptLoadPromise(src);
}
