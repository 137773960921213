import store from "@two-ui/store";
import { RouteLocationNormalized } from "vue-router";

/**
 * Checks if balances is enabled for a customer if required by route
 * @param to
 * @param from
 * @returns route or undefined
 */
export default function useBalancesRestrictionGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) {
  if (
    to.meta.requiresBalancesEnabledForCustomer &&
    !store.isFloatsFeatureEnabledForCustomer()
  ) {
    // if route opened directly using url, move to home
    if (!from.name) return { name: "home" };
  }
}
