<script lang="ts" setup>
import type { AccordionProvider } from "./types";
import TwoAccordionTransition from "./TwoAccordionTransition.vue";
import { inject, computed } from "vue";
import { v4 as uuidv4 } from "uuid";

const props = defineProps({
  title: {
    type: String,
  },
  isLargeTitle: {
    type: Boolean,
    default: false,
  },
  isOpenOnLoad: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const accordion = inject<AccordionProvider>("accordion");
const itemId = uuidv4();
const isOpen = computed(() => accordion?.activeItemIds?.value.includes(itemId));
const handleItemClick = () => {
  if (!props.disabled) accordion?.handleItemClick(itemId);
};
if (props.isOpenOnLoad) {
  accordion?.handleItemClick(itemId);
}
</script>
<template>
  <li class="border-b border-grey-100 py-4">
    <button
      @click="handleItemClick"
      type="button"
      class="flex w-full cursor-pointer items-center justify-between text-left"
      :disabled="disabled"
    >
      <p v-if="!isLargeTitle" class="text-base text-grey-800">{{ title }}</p>
      <h2 v-else class="my-2 text-2xl">{{ title }}</h2>
      <font-awesome-icon
        v-if="!disabled"
        class="cursor-pointer text-xl text-grey-600 duration-500"
        :class="{ 'chevron-accordion-open': isOpen }"
        :icon="['far', 'chevron-down']"
      ></font-awesome-icon>
    </button>
    <TwoAccordionTransition v-if="!disabled">
      <div class="overflow-hidden duration-500" v-show="isOpen" role="tabpanel">
        <div class="py-2">
          <slot />
        </div>
      </div>
    </TwoAccordionTransition>
  </li>
</template>
<style lang="sass" scoped>
.chevron-accordion-open
  @apply transform -rotate-180
</style>
