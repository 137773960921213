<script setup lang="ts">
import { v4 as uuidv4 } from "uuid";
import { computed, inject, ref } from "vue";
import { RadioGroupProvider } from "./types";

const props = defineProps({
  value: { type: [String, Number, Boolean], required: true },
  /**
   * To control whether to make label red when not checked after touched.
   */
  required: { type: Boolean, default: false },
  /**
   * No need to provide name if used via radio group and provided there
   */
  name: { type: String },
  /**
   *  No need to provide v-model if used via radio group and provided there
   */
  modelValue: { type: [String, Number, Boolean] },
  hideButton: { type: Boolean, default: false },
});
const emit = defineEmits(["update:modelValue", "change"]);

const id = uuidv4();
const touched = ref(false);
const onChange = () => {
  emit("update:modelValue", props.value);
  emit("change", props.value);
  radioGroup?.emit(props.value);
};

// radioGroup used to emit selection to radio group parent
// and also to get common values for all radio field from group parent
const radioGroup = inject<RadioGroupProvider | null>("radioGroup", null);

const showRequiredValidation = computed(
  () =>
    props.required &&
    touched.value &&
    (props.modelValue === null || props.modelValue === undefined)
);

const isCurrentRadioValueSelected = computed(
  () =>
    props.value === props.modelValue ||
    props.value === radioGroup?.modelValue.value
);
</script>

<template>
  <label
    :for="id"
    class="w-full cursor-pointer text-sm"
    :class="{
      'field-text-light': !showRequiredValidation,
      'field-error-text': showRequiredValidation,
    }"
  >
    <div
      class="field-border mb-2 flex items-center"
      :class="{
        'field-focus p-1 duration-200 hover:bg-grey-50': hideButton,
        'border border-grey-100': hideButton && !isCurrentRadioValueSelected,
        'border border-indigo-500': hideButton && isCurrentRadioValueSelected,
      }"
    >
      <div
        class="field-focus mr-2 inline-block h-3.5 w-3.5 rounded-full border-2 border-grey-100 text-sm"
        :class="{
          'bg-primary': isCurrentRadioValueSelected,
          // cannot hide it if hiddenButton as focus-within selector won't work in that case
          'invisible-no-space': hideButton,
        }"
      >
        <input
          type="radio"
          :id="id"
          :value="value"
          @change="onChange"
          class="invisible-no-space"
          :checked="isCurrentRadioValueSelected"
          :name="name || radioGroup?.name"
          @blur="touched = true"
        />
      </div>

      <slot /></div
  ></label>
</template>
<style lang="sass" scoped>
  @import "../styles/field"
.invisible-no-space
  @apply opacity-0 absolute
</style>
