<template>
  <div class="rounded-2xl p-5 text-black" :class="backgroundColor">
    <div class="flex gap-4">
      <div v-if="icon != null">
        <IconWithBackground
          :icon="icon"
          size="2x"
          class="ml-0.5"
          :class="iconBackgroundColor"
          v-if="type != 'loading'"
        />
        <div class="relative" v-else>
          <font-awesome-icon
            icon="circle"
            class="fa-2x icon-bg text-cyan-100"
            fixed-width
          />
          <LoadingSpinner
            size="2rem"
            stroke-width="0.8rem"
            class="absolute top-0 p-1.5"
            style="left: 3.5px"
          />
        </div>
      </div>
      <div class="m-1 grow">
        <header class="flex items-center justify-between">
          <b role="heading">{{ header }}</b>
          <button>
            <font-awesome-icon
              v-if="showCloseButton"
              @click="$emit('close')"
              :icon="['far', 'times']"
            ></font-awesome-icon>
          </button>
        </header>
        <div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { MessageBoxType } from "./types";
import IconWithBackground from "./IconWithBackGround.vue";
import { LoadingSpinner } from "../..";

export default defineComponent({
  components: { IconWithBackground, LoadingSpinner },
  props: {
    header: {
      type: String,
    },
    type: {
      type: String as PropType<MessageBoxType>,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],

  setup(props) {
    const backgroundColor = computed(() => {
      switch (props.type) {
        case "default":
          return "bg-grey-50";
        case "info":
          return "bg-cyan-50";
        case "loading":
          return "bg-cyan-50";
        case "success":
          return "bg-lime-50";
        case "warning":
          return "bg-yellow-50";
        case "error":
          return "bg-red-50";
        default:
          return "bg-grey-50";
      }
    });

    const icon = computed(() => {
      if (props.hideIcon) {
        return null;
      }

      switch (props.type) {
        case "default":
          return ["far", "circle-exclamation"];
        case "success":
          return ["far", "check"];
        case "warning":
          return ["far", "triangle-exclamation"];
        case "error":
          return ["far", "triangle-exclamation"];
        case "info":
          return ["far", "lightbulb"];
        case "loading":
          return ["far", "lightbulb"];
        default:
          return ["far", "circle-exclamation"];
      }
    });
    const iconBackgroundColor = computed(() => {
      if (props.hideIcon) {
        return null;
      }

      switch (props.type) {
        case "default":
          return "";
        case "success":
          return "icon-success";
        case "warning":
          return "icon-warning";
        case "error":
          return "icon-danger";
        case "info":
          return "icon-info";
        case "loading":
          return "icon-info";
        default:
          return "icon-bg";
      }
    });

    return {
      backgroundColor,
      icon,
      iconBackgroundColor,
    };
  },
});
</script>
