<script lang="ts">
import { SelectOption } from "./types";
import { computed, defineComponent, PropType, ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import FieldLabel from "./FieldLabel.vue";
import FieldGradientBorderWrapper from "./FieldGradientBorderWrapper.vue";
import TwoCurrencyAvatar from "./TwoCurrencyAvatar.vue";
import { ThemeType, CurrencyAvatarType } from "./types";

export default defineComponent({
  components: {
    FontAwesomeIcon,
    FieldGradientBorderWrapper,
    FieldLabel,
    TwoCurrencyAvatar,
  },
  emits: ["update:modelValue", "change"],
  props: {
    label: {
      type: String,
    },
    options: {
      type: Object as PropType<SelectOption[]>,
      required: true,
    },
    modelValue: {
      type: String,
    },
    /**
     * Description to show below the field label
     */
    description: {
      type: String,
    },
    /**
     * To allow disabling field
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * To show text indicating field is required below the field after touched
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Show any custom error text below the field.
     * Will show required text first is required prop is set and field is empty.
     */
    customError: { type: String },
    /**
     * Used to indicate in label that field is optional
     */
    showOptionalHint: { type: Boolean, default: false },
    /**
     * Used to apply a currency avatar to the select
     */
    currencyAvatar: { type: Object as PropType<CurrencyAvatarType> },
    /**
     * To control light or dark theme for the component. Default is light
     */
    theme: { type: String as PropType<ThemeType>, default: "light" },

    customSelectClass: {
      type: String,
      required: false,
    },
  },

  setup(props, { emit }) {
    const id = uuidv4();
    const touched = ref(false);
    const showRequiredValidation = computed(
      () => touched.value && props.required && !props.modelValue
    );
    const showCustomValidation = computed(
      () => touched.value && !!props.customError
    );
    const onChange = (event: Event) => {
      const { value } = event.target as HTMLInputElement;
      emit("update:modelValue", value);
      emit("change", value);
    };

    return {
      id,
      touched,
      showRequiredValidation,
      showCustomValidation,
      onChange,
    };
  },
});
</script>

<template>
  <div>
    <FieldLabel
      v-if="label"
      class="mb-2"
      :label="label"
      :show-optional-hint="showOptionalHint"
      :for="id"
      :description="description"
    />

    <div class="relative">
      <FieldGradientBorderWrapper
        :show-error="showRequiredValidation || showCustomValidation"
        :theme="theme"
      >
        <div
          v-if="currencyAvatar"
          class="absolute bottom-0 top-0 ml-4 flex items-center"
        >
          <TwoCurrencyAvatar
            :src="currencyAvatar.src"
            :currency="currencyAvatar.currency"
          ></TwoCurrencyAvatar>
        </div>
        <select
          class="field-inner-border field-padding z-0 w-full appearance-none truncate !pr-8 outline-none"
          :class="[
            customSelectClass
              ? customSelectClass
              : theme === 'light'
              ? 'field-background-light'
              : 'field-background-dark',
            currencyAvatar
              ? 'field-text-transparent'
              : theme === 'light'
              ? 'field-text-light'
              : 'field-text-dark',
          ]"
          :disabled="disabled"
          :required="required"
          :value="modelValue"
          @blur="touched = true"
          @input="onChange"
          :id="id"
        >
          <option
            class="truncate p-2 text-black"
            v-for="option in options"
            :key="option.id"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </FieldGradientBorderWrapper>

      <div
        class="leading-1 pointer-events-none absolute right-3 top-4 z-10 text-sm"
      >
        <font-awesome-icon
          :icon="['far', 'chevron-down']"
          :class="{
            'field-icon-light': theme === 'light',
            'field-icon-dark': theme === 'dark',
          }"
        />
      </div>
    </div>
    <!-- Error texts -->
    <div>
      <p class="field-error-text" v-if="showRequiredValidation">
        This field is required.
      </p>
      <p class="field-error-text" v-else-if="showCustomValidation">
        {{ customError }}
      </p>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import "../styles/field"
</style>
