import { defineStore } from "pinia";
import {
  RouteRecordRaw,
  RouteRecordName,
  RouteLocationNormalizedLoaded,
} from "vue-router";
import { routes } from "@two-ui/router";
const flattenRoute = (router: RouteRecordRaw[]): RouteRecordRaw[] => {
  return router.reduce((acc, route) => {
    if (route.children) {
      return [...acc, route, ...flattenRoute(route.children)];
    }
    return [...acc, route];
  }, [] as RouteRecordRaw[]);
};

export const useBreadCrumb = defineStore("breadCrumbTwoUI", {
  state: () => ({
    items: [] as { label: string; routerName: RouteRecordName | undefined }[],
  }),
  actions: {
    generateBreadCrumb(route: RouteLocationNormalizedLoaded) {
      const breadCrumb = flattenRoute(routes).filter(
        (item, index) =>
          item.path && // item.path check exclude routes where item.path is "", to exclude root routes of
          // all child apps e.g. billing-home.
          ((index === 0 && route.path.includes(item.path as string)) ||
            route.name === item.name ||
            route.path.includes(item.path as string))
      ) as RouteRecordRaw[];
      this.items = breadCrumb
        .filter((route) => route.meta?.breadCrumbTitle)
        .map((route: RouteRecordRaw) => ({
          label: route.meta?.breadCrumbTitle as string,
          routerName: route.name,
        }));
    },
    updateBreadCrumb({
      routerName,
      label,
    }: {
      routerName: string;
      label: string;
    }) {
      this.items = this.items.map((item) => {
        if (routerName === item.routerName) {
          return {
            routerName,
            label,
          };
        }
        return {
          routerName: item.routerName,
          label: item.label,
        };
      });
    },
  },
});
