<script setup lang="ts">
import { computed, inject } from "vue";
import type { TableColumnSortProvider } from "../types";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const props = defineProps({
  dataKey: {
    type: String,
  },
  sortable: {
    type: Boolean,
    default: false,
  },
});
const sorter = inject<TableColumnSortProvider>("columnSorter");
const currentSortValue = computed(() => {
  if (props.dataKey) return sorter?.currentColumnSort.value[props.dataKey];
});
const sortColumn = () => {
  if (props.dataKey) {
    sorter?.sortColumn(props.dataKey);
  } else {
    console.warn("dataKey prop required for sorting");
  }
};
</script>
<template>
  <th
    class="border-b border-grey-50 py-3 pl-2 pr-4 text-left text-sm font-semibold text-grey-400"
  >
    <button
      v-if="sortable"
      @click="sortColumn"
      class="header-text-inherit flex w-full items-center justify-between"
    >
      <slot />

      <div class="pr-4 text-grey-400">
        <font-awesome-icon
          v-if="currentSortValue === 'asc'"
          :icon="['far', 'sort-up']"
        ></font-awesome-icon>
        <font-awesome-icon
          v-else-if="currentSortValue === 'desc'"
          :icon="['far', 'sort-down']"
        ></font-awesome-icon>
        <font-awesome-icon v-else :icon="['far', 'sort']"></font-awesome-icon>
      </div>
    </button>

    <template v-else>
      <slot />
    </template>
  </th>
</template>
<style lang="sass" scoped>
.header-text-inherit
  font-weight: inherit
  color: inherit
</style>
