import { User as CmsUser } from "@workspace/open-api/cms";
import { importScript } from "@two-ui/util/script-loader";
import { wait } from "@two-ui/util/async";

// Add hubspot script to end of body
// Only called once app is in logged in state as we don't want elements added to loading states
let hubspotScriptAdded = false;
export async function addHubspotScript() {
  await importScript("https://js.hs-scripts.com/20335080.js");
  // after importing script Hubspot script, window._hsq takes
  // some time to populate for some reason hence adding a bit of wait.
  await wait(2000);
}

// Remove the messages button from the screen, cannot be reversed
// Should be run as user is logging out so spinner screen is clean.
export function removeHubspotMessages() {
  const hubspotMessages = document.querySelectorAll(
    "#hubspot-messages-iframe-container"
  );
  hubspotMessages.forEach((message) => message.remove());
}

export async function identifyUser(user: CmsUser) {
  console.debug("Setting up Hubspot for current user");
  const w = window as any;
  const _hsq = w._hsq;
  if (!_hsq) {
    console.warn(
      `Hubspot not loaded, so will not identify user in Hubspot data`
    );
    return;
  }
  const userToIdentify = {
    id: user.id,
    email: user.emailAddress,
    first_name: user.firstName,
    last_name: user.lastName,
    customer_ids: user.customerIds.join(","),
  };
  console.debug(
    "Current user has consented to data processing, identify user with Hubspot",
    userToIdentify
  );

  _hsq.push(["identify", userToIdentify]);
}
