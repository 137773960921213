import { RouteLocationNormalized } from "vue-router";
import { GuardAction, JwtPayload } from "../types";
import { getJwtToken } from "../auth0";
import jwtDecode from "jwt-decode";

export class Auth0NoPermissionsNavigationError extends Error {
  constructor(message?: string) {
    super(message);
  }
}
/**
 * Checks if route requires permission.
 * If it requires and not set, if route opened directly via url, it returns to home page. If opened
 * using router navigation, throws customer error that can be handled using router.onError e.g. to show some feedback.
 * Returns undefined if there is no issue with performing the navigation.
 * @param to
 * @param from
 * @returns route or undefined
 */
export default async function auth0PermissionsRestrictionGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) {
  // if route doesn't have an RBAC policy, return
  if (!to.meta.permissionGuards) return;

  // Extract user's current permissions from JWT
  // If token is cached, and has not expired, it will be used
  // otherwise, it will be fetched from auth0
  const decoded = await getJwtToken().then((t) => jwtDecode<JwtPayload>(t));
  const userPermissions = decoded.permissions;

  const shouldAllow =
    to.meta.permissionGuards.guardAction === GuardAction.AND
      ? to.meta.permissionGuards.permissions.every((p) =>
          userPermissions?.includes(p)
        )
      : to.meta.permissionGuards.permissions.some((p) =>
          userPermissions?.includes(p)
        );

  if (!shouldAllow) {
    // if route opened directly using url, move to home
    if (!from.name) return { name: "home" };
    // throw error that can be handled using router.onError
    throw new Auth0NoPermissionsNavigationError();
  }
}
