<script lang="ts" setup>
import { provide, ref } from "vue";
import type { AccordionProvider } from "./types";

const props = defineProps({
  allowMultipleExpanded: {
    type: Boolean,
    default: false,
  },
});
const activeItemIds = ref<string[]>([]);
const handleItemClick = (itemId: string) => {
  if (activeItemIds.value.includes(itemId)) {
    activeItemIds.value = activeItemIds.value.filter(
      (value) => value !== itemId
    );
  } else {
    if (!props.allowMultipleExpanded) {
      activeItemIds.value = [];
    }
    activeItemIds.value.push(itemId);
  }
};
provide<AccordionProvider>("accordion", {
  activeItemIds,
  handleItemClick,
});
</script>
<template>
  <ul role="tablist">
    <slot />
  </ul>
</template>
