<script setup lang="ts"></script>
<template>
  <div class="w-full rounded-xl border border-grey-100 px-4 py-2">
    <table class="w-full border-separate border-spacing-y-2">
      <slot />
    </table>
  </div>
</template>

<style scoped lang="sass"></style>
