<script setup lang="ts">
import { ref } from "vue";
import LoadingSpinner from "./LoadingSpinner.vue";
import { useGlobalStore } from "@workspace/packages/two-ui/src/global-store";
import { DropdownButtonOptions } from "./types";
import vClickOutside from "../directives/ClickOutside";

// Props definition
const props = defineProps<{
  options: DropdownButtonOptions[];
  loading: boolean;
  disabled: boolean;
}>();

const emit = defineEmits(["toggle", "select"]);

// State
const isDropdownOpen = ref(false);

// Methods
const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
  emit("toggle", isDropdownOpen.value);
};

const selectOption = (option: string) => {
  emit("select", option);
};

const closeDropdown = () => {
  isDropdownOpen.value = false;
};
</script>

<template>
  <div class="flex items-center">
    <div class="flex flex-grow flex-col items-start">
      <div class="flex flex-col gap-y-1">
        <button
          data-testid="dropdown-button"
          class="btn"
          :disabled="props.loading || props.disabled"
          @click="toggleDropdown"
          v-click-outside="closeDropdown"
        >
          <div class="content-container">
            <slot></slot>
            <LoadingSpinner
              v-if="props.loading"
              color="#AAAAAA"
              strokeWidth="7"
              class="-mt-1 mr-2 inline h-4 w-4 items-center"
            />
            <font-awesome-icon
              v-else
              :icon="['far', 'chevron-down']"
              class="-mt-1 mr-2 inline h-4 w-4 items-center"
            />
          </div>
        </button>
        <div v-show="isDropdownOpen && !props.loading" class="flex flex-col">
          <ul
            id="dropdownLinks"
            class="bg-gray-100 custom-shadow absolute z-50 rounded-xl border border-grey-100 bg-white px-4 py-1"
          >
            <li
              class="flex justify-items-center px-2"
              v-for="option in props.options"
              :key="option.label"
            >
              <a
                :data-testid="option.label"
                class="no-underline"
                :href="option.href"
                @click.prevent="selectOption(option.label)"
                ><span> {{ option.label }}</span></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.custom-shadow
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.30)

#dropdownLinks
  width: 126px

li:hover
  @apply bg-grey-50

.btn
  @apply px-4 py-3 bg-white text-sm text-white rounded-xl  border-black bg-black
  @apply hover:bg-grey-600
  @apply focus-visible:outline-none focus-visible:ring focus-visible:ring-cyan-300
  @apply active:bg-grey-800
  @apply disabled:opacity-50 disabled:pointer-events-none

.content-container
  @apply flex justify-between items-center flex-1 gap-1
</style>
