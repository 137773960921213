import mixpanel, { Dict } from "mixpanel-browser";
import {
  User as CmsUser,
  ApiError as CmsApiError,
  CustomerListItem as CmsCustomer,
} from "@workspace/open-api/cms";
import { CustomerService } from "@workspace/open-api/cms";
import { addBreadcrumb, captureException } from "./sentry";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";

const MIXPANEL_ENABLE = import.meta.env.VITE_MIXPANEL_ENABLE;
const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN;

const MIXPANEL_SAMPLE_ENABLE = import.meta.env.VITE_MIXPANEL_SAMPLE_ENABLE;
const MIXPANEL_SAMPLE_RATE = import.meta.env.VITE_MIXPANEL_SAMPLE_RATE;

let mixpanelReady = false;

/*
For registering properties passed to all events. Mixpanel provides a .register method,
but does not provide methods to get all properties or unregister all hence tracking through this variable
*/
let REGISTERED_PROPERTIES: Dict = {};
export function initMixpanel() {
  if (MIXPANEL_ENABLE !== "true") {
    return;
  }
  if (!MIXPANEL_TOKEN) {
    console.warn("No mixpanel token set, not tracking");
    return;
  }
  if (mixpanelReady) return;
  mixpanel.init(MIXPANEL_TOKEN);
  mixpanelReady = true;
}

export async function trackEvent(
  event_name: string,
  properties: Dict = {},
  sample_idempotency_key?: string
) {
  if (MIXPANEL_ENABLE !== "true") {
    return;
  }

  if (MIXPANEL_SAMPLE_ENABLE && !shouldSampleEvent(sample_idempotency_key)) {
    return;
  }

  const propertiesToTrack = { ...REGISTERED_PROPERTIES, ...properties };
  addBreadcrumb({
    message: event_name,
    category: "mixpanel",
    data: { event_name, ...propertiesToTrack },
  });
  if (!mixpanelReady) {
    console.debug("Mixpanel not setup, would have tracked", {
      event_name,
      properties: propertiesToTrack,
    });
    return;
  }
  try {
    mixpanel.track(event_name, propertiesToTrack);
    console.debug("Tracked event", {
      event_name,
      properties: propertiesToTrack,
    });
  } catch (exception) {
    captureException(exception);
  }
}

function shouldSampleEvent(sample_idempotency_key?: any) {
  if (!sample_idempotency_key) {
    sample_idempotency_key = uuidv4();
  }

  // Generate a deterministic hash number from the key
  const hashNumber = hashStringToNumber(sample_idempotency_key);

  // Use the hash number to determine if the event should be sampled
  const samplingRate = MIXPANEL_SAMPLE_RATE ?? 1;
  const shouldSample = hashNumber % 100 < samplingRate * 100;

  return shouldSample;
}

function hashStringToNumber(str: string) {
  const hash = CryptoJS.MD5(str).toString(CryptoJS.enc.Hex);
  // Convert the hash to a number & take first 8 chars
  const num = parseInt(hash.slice(0, 8), 16);

  return num;
}

export async function setUserPropertyOnce(property: string, value?: any) {
  if (!mixpanelReady) {
    console.debug("Mixpanel not setup, would have set user property", {
      property: property,
      value: value,
    });
    return;
  }
  try {
    mixpanel.people.set_once(property, value);
    console.debug("Set user property", {
      property: property,
      value: value,
    });
  } catch (exception) {
    captureException(exception);
  }
}

export async function incrementUserProperty(property: string, value: number) {
  if (!mixpanelReady) {
    console.debug("Mixpanel not setup, cannot increment user property", {
      property: property,
      value: value,
    });
    return;
  }
  try {
    mixpanel.people.increment(property, value);
    console.debug("Incremented user property", {
      property: property,
      value: value,
    });
  } catch (exception) {
    captureException(exception);
  }
}

/**
 * Register properties for passing to all subsequent events
 */
export async function registerMixpanelProperties(properties: Dict) {
  REGISTERED_PROPERTIES = { ...REGISTERED_PROPERTIES, ...properties };
}

/**
 * Unregister specified properties.
 * If Dict is passed, un-registers all keys of the dict ignoring value.
 * @param properties string[] | Dict
 */
export async function unregisterMixpanelProperties(
  properties: string[] | Dict
) {
  const propertiesToUnregister = Array.isArray(properties)
    ? properties
    : Object.keys(properties);
  propertiesToUnregister.forEach(
    (property) => delete REGISTERED_PROPERTIES[property]
  );
}

interface TrackedCustomer {
  id: string;
  name: string;
}

interface TrackedUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  customer_ids: Array<string>;
  customers?: Array<TrackedCustomer>;
  // Only set if user has a single customer
  singleCustomerId?: string;
  singleCustomerName?: string;
}

async function getCustomersForUser(): Promise<CmsCustomer[]> {
  try {
    return await CustomerService.getCustomerList();
  } catch (exception) {
    if (exception instanceof CmsApiError) {
      console.debug("Unable to fetch customer list");
    }
  }
  return [];
}

export async function identifyUser(user: CmsUser) {
  if (!mixpanelReady) return;

  try {
    // Identify early so we catch early events.
    mixpanel.identify(user.id);
  } catch (exception) {
    captureException(exception);
  }

  const customers = await getCustomersForUser();
  const trackedUser: TrackedUser = {
    id: user.id,
    email: user.emailAddress,
    first_name: user.firstName,
    last_name: user.lastName,
    customer_ids: user.customerIds,
    customers,
    ...(customers.length === 1
      ? {
          singleCustomerId: customers[0].id,
          singleCustomerName: customers[0].name,
        }
      : []),
  };

  try {
    // Set profile for the identified user.
    mixpanel.people.set({
      $first_name: trackedUser.first_name,
      $last_name: trackedUser.last_name,
      $email: trackedUser.email,
      ...trackedUser,
    });
    console.debug("Set Mixpanel tracked user", { id: user.id, trackedUser });
  } catch (exception) {
    captureException(exception);
  }
}

export default mixpanel;
