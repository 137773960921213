<script lang="ts" setup>
import { doLogin } from "@two-ui/auth0";
import FullPageLoader from "@two-ui/components/FullPageLoader.vue";
import { onMounted } from "vue";

onMounted(() => {
  const queryParams = new URLSearchParams(window.location.search);
  const emailAddress = queryParams.get("email");
  doLogin({ screen_hint: "signup", login_hint: emailAddress ?? undefined });
});
</script>

<template>
  <FullPageLoader>Let's signup…</FullPageLoader>
</template>
