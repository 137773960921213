import {
  faPhone,
  faDown,
  faAngleDown,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faBug,
  faCalendarTimes,
  faChevronDown,
  faChevronUp,
  faClone,
  faCopy,
  faDownload,
  faEnvelope,
  faExternalLinkAlt,
  faEye,
  faInfoCircle,
  faLink,
  faPlus,
  faQuestionCircle,
  faReceipt,
  faSearch,
  faShapes,
  faSort,
  faSortDown,
  faSortUp,
  faSwatchbook,
  faTimes,
  faTurnDownLeft,
  faUser,
  faUsers,
  faUserShield,
  faCommentSmile,
  faTrash,
  faPaperPlane,
  faSlidersH,
  faRectangleHistory,
  faImage,
  faCreditCard,
  faCoins,
  faKey,
  faCheck,
  faLightbulb,
  faPuzzlePieceSimple,
  faGrid2,
  faBank,
  faFile,
  faArrowUpRightFromSquare,
  faFilter,
  faCartShopping,
  faRightLeft,
  faChevronLeft,
  faChevronRight,
  faPen,
  faPrint,
  faCode,
  faBan,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faEnvelopeOpenText as fadEnvelopeOpenText,
  faWhale as fadWhale,
  faSquareCheck,
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faCircle,
  faHome,
  faEllipsisVertical,
  faExclamationSquare,
  faAngleDown as fasAngleDown,
  faAngleUp as fasAngleUp,
  faList,
  faKey as fasKey,
  faXmark,
  faCartShopping as fasCartShopping,
  faPlus as fasPlus,
  faGear,
  faArrowDownToBracket,
  faEllipsis,
} from "@fortawesome/pro-solid-svg-icons";
import { faJira, faGitlab } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
  faDown,
  faAngleDown,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faArrowUpRightFromSquare,
  faBug,
  faCalendarTimes,
  faChevronDown,
  faChevronUp,
  faClone,
  faCopy,
  faDownload,
  faEllipsisVertical,
  faEnvelope,
  faExternalLinkAlt,
  faEye,
  faInfoCircle,
  faLink,
  faPlus,
  fasPlus,
  faQuestionCircle,
  faReceipt,
  faSearch,
  faShapes,
  faSort,
  faSortDown,
  faSortUp,
  faSwatchbook,
  faTimes,
  faTurnDownLeft,
  faUser,
  faUsers,
  faUserShield,
  faCommentSmile,
  // @ts-ignore
  faHome,
  faTrash,
  faSlidersH,
  faCircle,
  faPaperPlane,
  faJira,
  faGitlab,
  faRectangleHistory,
  faImage,
  faCreditCard,
  faCoins,
  faKey,
  faCheck,
  faLightbulb,
  faExclamationSquare,
  faPuzzlePieceSimple,
  fasAngleUp,
  fasAngleDown,
  faGrid2,
  faBank,
  faList,
  faFile,
  faArrowUpRightFromSquare,
  faFilter,
  faCartShopping,
  fasCartShopping,
  faRightLeft,
  faChevronLeft,
  faChevronRight,
  faPen,
  faXmark,
  faPrint,
  faCode,
  faPhone,
  faGear,
  faArrowDownToBracket,
  faEllipsis,
  faBan
);

// Duotone icons
library.add(
  // @ts-ignore
  fadEnvelopeOpenText,
  fadWhale,
  faSquareCheck
);
