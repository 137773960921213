<!-- 
  Popover component, uses popper.js to position a popover which we hides/show based on user mouse events.
 -->

<script lang="ts" setup>
import { computed, nextTick, ref, useSlots } from "vue";
import vClickOutside from "../directives/ClickOutside";

import {
  createPopperLite as createPopper,
  preventOverflow,
  flip,
  Placement,
  arrow,
} from "@popperjs/core";
import { assert } from "../util/typescript";

const props = withDefaults(
  defineProps<{
    /**
     * Popover placement w.r.t. trigger element e.g. right, top, auto, top-right For all available options, see Popperjs placement type
     */
    placement?: Placement;
    /**
     * Trigger to open popover
     */
    trigger?: "click" | "hover";
    /**
     * Classes to add on popover element
     */
    popoverClass?: string;
    /**
     *  Offset of the popover by [x,y] px
     */
    offset?: [number, number];
    /**
     *  Popup header text
     */
    headerText?: string;
  }>(),
  {
    placement: "auto",
    trigger: "hover",
    popoverClass: "",
  }
);
const slots = useSlots();

const triggerContainer = ref<HTMLElement>();
const popoverContainer = ref<HTMLElement>();
const popoverVisible = ref(false);
const renderPopover = ref(false);

const initializePopover = () => {
  if (!slots.reference) {
    console.warn(
      "Cannot initialize popover without a reference template for triggering"
    );
    return;
  }
  if (!slots.default) {
    console.warn("Cannot initialize popover without a default template");
    return;
  }
  assert(
    popoverContainer.value && triggerContainer.value,
    "Error collecting element refrences for containers"
  );
  createPopper(triggerContainer.value, popoverContainer.value, {
    placement: props.placement,
    modifiers: [
      preventOverflow,
      flip,
      arrow,
      ...(props.offset
        ? [{ name: "offset", options: { offset: props.offset } }]
        : []),
    ],
  });
};

const initializeAndRenderPopover = async () => {
  renderPopover.value = true;
  await nextTick();
  initializePopover();
};

const popoverClassAsObject = computed(() => ({ [props.popoverClass]: true }));
const handleKeydown = (e: KeyboardEvent) => {
  if (e.key === "Escape") {
    popoverVisible.value = false;
  }
};
</script>

<template>
  <div
    class="inline-block"
    @keydown="handleKeydown"
    v-click-outside="() => (popoverVisible = false)"
    @click="
      if (trigger === 'click') {
        initializeAndRenderPopover();
        popoverVisible = !popoverVisible;
      }
    "
    @mouseover="
      if (trigger === 'hover') {
        initializeAndRenderPopover();
        popoverVisible = true;
      }
    "
    @mouseleave="
      if (trigger === 'hover') {
        popoverVisible = false;
      }
    "
    @touchstart="initializeAndRenderPopover"
  >
    <div ref="triggerContainer">
      <slot name="reference" />
    </div>
    <template v-if="renderPopover">
      <div
        role="tooltip"
        class="z-50 rounded border border-grey-100 bg-white p-4 shadow-lg"
        :class="// visibility should be used instead of display as per popper docs. important for calculating position.
        {
          visible: popoverVisible,
          invisible: !popoverVisible,
          ...popoverClassAsObject,
        }"
        ref="popoverContainer"
        @click.stop
        @mouseover="
          if (trigger === 'hover') {
            popoverVisible = true;
          }
        "
        @mouseleave="
          if (trigger === 'hover') {
            popoverVisible = false;
          }
        "
      >
        <div class="flex items-center justify-between gap-4">
          <div class="text-xl font-bold" v-if="headerText">
            {{ headerText }}
          </div>
          <div
            class="cursor-pointer"
            @click="popoverVisible = false"
            v-if="headerText && trigger === 'click'"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.6449 2.04935C12.1134 1.58082 12.1134 0.819928 11.6449 0.351398C11.1763 -0.117133 10.4154 -0.117133 9.9469 0.351398L6 4.30205L2.04935 0.355146C1.58082 -0.113384 0.819928 -0.113384 0.351398 0.355146C-0.117133 0.823676 -0.117133 1.58457 0.351398 2.0531L4.30205 6L0.355146 9.95065C-0.113384 10.4192 -0.113384 11.1801 0.355146 11.6486C0.823676 12.1171 1.58457 12.1171 2.0531 11.6486L6 7.69795L9.95065 11.6449C10.4192 12.1134 11.1801 12.1134 11.6486 11.6449C12.1171 11.1763 12.1171 10.4154 11.6486 9.9469L7.69795 6L11.6449 2.04935Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
        <slot :close="() => (popoverVisible = false)" />
      </div>
    </template>
  </div>
</template>
