import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing"; // Must import second
import { Integrations } from "@sentry/tracing";
import { App } from "vue";
import { Router } from "vue-router";
import { RELEASE_BRANCH } from "@two-ui/constants";
import { User } from "@workspace/open-api/cms";

function getSentryEnvironment(environment: string): string {
  const is_pull_request = import.meta.env.VITE_PULL_REQUEST;
  if (is_pull_request === "true") {
    // Deploy previews don't count as errors on a particular environment
    return "PREVIEW";
  }
  return environment;
}

function getSentryRelease(): string | undefined {
  const branch = import.meta.env.VITE_BRANCH;
  const is_pull_request = import.meta.env.VITE_PULL_REQUEST;
  const commit_ref = import.meta.env.VITE_COMMIT_REF;
  if (
    branch === undefined ||
    is_pull_request === undefined ||
    commit_ref === undefined
  ) {
    // Not a release, ignore
    return undefined;
  }
  if (branch === RELEASE_BRANCH && is_pull_request === "false") {
    // Is a release, use short form commit ref
    return commit_ref.slice(0, 7);
  }
  return undefined;
}

// Attach sentry to the given app/router
export function initSentry({
  app,
  router,
  dsn,
  environment,
}: {
  app: App;
  router: Router;
  dsn: string;
  environment: string;
}) {
  Sentry.init({
    app,
    dsn: dsn,
    environment: getSentryEnvironment(environment),
    release: getSentryRelease(),
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          // TODO: Enable these origins when we've updated & validated they can accept the new 'baggage' header
          // import.meta.env.VITE_VLOS_BASE_URL,
          // import.meta.env.VITE_SCION_BASE_URL,
          // import.meta.env.VITE_CMS_BASE_URL,
          // import.meta.env.VITE_INTERCOM_TOKEN_SERVICE,
          // import.meta.env.VITE_API_KEY_SERVICE_BASE_URL,
        ],
      }),
      new Sentry.Replay({
        // Tweak masking parameters
        // Instead of masking all input we explicitly mask fields with PII
        // See https://docs.sentry.io/platforms/javascript/session-replay/privacy/
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // Sentry hides errors from console by default, don't
    logErrors: true,
    ignoreErrors: [
      // This exception is specific to a project where Chromium is embedded in C# and should therefore be relatively
      // safe to ignore without losing actual errors. We are ignoring it to avoid repeating event flooding
      "Non-Error promise rejection captured with value: Object Not Found Matching Id:",
      // Fixing error flooding issue associated with Sentry:
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
      "Auth0LoginRequired",
    ],
    // This sets the sample rate. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.2,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}

export function initSentryFromEnv({
  app,
  router,
}: {
  app: App;
  router: Router;
}) {
  const dsn = import.meta.env.VITE_SENTRY_DSN;
  const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT;
  if (!dsn) {
    console.debug("Not installing sentry as missing DSN");
    return;
  }
  if (!environment) {
    console.debug("Not installing sentry as missing environment");
    return;
  }
  initSentry({ app, router, dsn, environment });
}

export function setSentryUserContext(user: User) {
  Sentry.setUser({
    id: user.id,
    username: `${user.firstName} ${user.lastName}`,
    email: user.emailAddress,
  });
}

// Proxy function to make importing easier
export function addBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
  Sentry.addBreadcrumb(breadcrumb);
}

// Proxy function to make importing easier
export function captureMessage(message: string) {
  Sentry.captureMessage(message);
  console.error(message);
}

// Proxy function to make importing easier
export function captureException(exception: any) {
  Sentry.captureException(exception);
  console.error(exception);
}
