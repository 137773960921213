<script lang="ts" setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { SidebarProvider } from "../types";
import { computed, inject, ref } from "vue";
import { assert } from "../../util/typescript";
import { trackEvent } from "@two-ui/mixpanel";

const props = defineProps<{
  /**
   * Vue Router's route name
   */
  routeName: string;
  trackingEvent?: string;
  trackingProperties?: Record<string, string>;
  /**
   * icon object from FontAwesomeIcon props i.e. FontAwesomeIcon["icon"]
   */
  icon?: typeof FontAwesomeIcon["icon"];
  isAccordion?: boolean;
  expanded?: boolean;
}>();

const emit = defineEmits(["accordion-click"]);

const sidebarProviderInjectedValues =
  inject<SidebarProvider>("sidebarProvider");

const isSelected = computed(
  () =>
    sidebarProviderInjectedValues?.selectedRouteName?.value === props.routeName
);

const onSelectionChange = () => {
  if (props.isAccordion) {
    emit("accordion-click", props.routeName);
  } else {
    assert(sidebarProviderInjectedValues, "Cannot find injected values");
    if (typeof props.trackingEvent !== "undefined") {
      trackEvent(`Sidebar: Clicked on ${props.trackingEvent}`, {
        ...props.trackingProperties,
      });
    }
    sidebarProviderInjectedValues.router.push({ name: props.routeName });
  }
};
</script>
<template>
  <li
    class="flex bg-black px-6 pb-2 pt-3 font-semibold text-grey-100 transition-colors"
    :class="{
      selected: isSelected,
    }"
    @click="onSelectionChange"
  >
    <button :name="`sidebar-item-${routeName}`" class="w-full text-left">
      <font-awesome-icon
        v-if="icon"
        :icon="icon"
        class="mr-2"
        fixed-width
      ></font-awesome-icon>
      <slot />
    </button>

    <span v-if="isAccordion" class="cursor-pointer">
      <font-awesome-icon
        :icon="['far', 'chevron-up']"
        :class="props.expanded ? 'fa-arrow-down' : 'open'"
        fixed-width
      ></font-awesome-icon>
    </span>
  </li>
</template>
<style lang="sass" scoped>
.fa-arrow-down
  transform: rotate(0deg)
  transition: transform 0.2s linear

.open
  transform: rotate(180deg)
  transition: transform 0.2s linear

.selected
  @apply bg-lime-300 text-black
</style>
