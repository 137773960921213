<!--
    A little bookmark like tag thing to show the current environment when not on prod
    When clicked it takes you to the commit that was deployed in GitLab
-->

<script lang="ts" setup>
const mode = import.meta.env.MODE;
const branchName = import.meta.env.VITE_BRANCH;
const commitRef = import.meta.env.VITE_COMMIT_REF;

const shouldShow = mode !== "production";
const isSandbox = branchName === "master" && mode === "sandbox";
const isPreview = branchName !== "master" && mode === "sandbox";
const isLocal = mode === "development";
const gitlabUrl = commitRef
  ? `https://gitlab.com/wegift/two-ui/-/commit/${commitRef}`
  : "#";
</script>

<template>
  <a
    v-if="shouldShow"
    :href="gitlabUrl"
    :target="gitlabUrl !== '#' ? '_blank' : '_self'"
    class="rotate-fixed-left fixed right-0 top-6 z-50 -rotate-90 transform-gpu px-2 pb-1 text-sm font-semibold lowercase text-white no-underline"
    :class="{
      'bg-indigo-400': isSandbox,
      'bg-cyan-500': isPreview,
      'bg-lime-400': isLocal,
    }"
    :title="commitRef"
  >
    <font-awesome-icon
      v-if="gitlabUrl !== '#'"
      :icon="['fab', 'gitlab']"
      class="-mb-0.5 mr-1"
    />
    <span v-if="isSandbox">Sandbox</span>
    <span v-else-if="isPreview">{{ branchName }}</span>
    <span v-else-if="isLocal">Local</span>
  </a>
</template>

<style lang="sass" scoped>
.rotate-fixed-left
    transform-origin: bottom right
</style>
