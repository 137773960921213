export function formatCurrency(
  value: number,
  currency: string,
  options: Intl.NumberFormatOptions = {}
): string {
  let currencyDisplay = options["currencyDisplay"]
    ? options["currencyDisplay"]
    : "narrowSymbol";
  return new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency,
    currencyDisplay: options["currencyDisplay"] || currencyDisplay,
    ...options,
  }).format(value);
}

export function formatAsPercentage(num: number, decimalPlaces: number) {
  return num.toLocaleString(undefined, {
    style: "percent",
    maximumFractionDigits: decimalPlaces,
  });
}

export const truncateToDecimalPlaces = (
  value: number,
  currencyCode: string
) => {
  const { maximumFractionDigits } = new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: currencyCode,
  }).resolvedOptions();
  // Using string manipulation to avoid precision errors associated with float calculations
  const intPart = Math.trunc(value).toString();
  const fractionPart =
    value.toString().indexOf(".") !== -1
      ? value.toString().slice(value.toString().indexOf(".") + 1)
      : "";
  if (fractionPart.length > maximumFractionDigits) {
    return Number(
      intPart.concat(".", fractionPart.slice(0, maximumFractionDigits))
    );
  }
  const padded = intPart.concat(
    ".",
    fractionPart.padEnd(maximumFractionDigits, "0")
  );
  return Number(padded);
};

export const preserveFractionalZeros = (
  value: number,
  currencyCode: string
): string => {
  let padded: string;
  const { maximumFractionDigits } = new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: currencyCode,
  }).resolvedOptions();

  // Using string manipulation to avoid precision errors associated with float calculations
  const intPart = Math.trunc(value).toString();
  const fractionPart =
    value.toString().indexOf(".") !== -1
      ? value.toString().slice(value.toString().indexOf(".") + 1)
      : "";

  if (fractionPart.length > maximumFractionDigits) {
    return intPart.concat(".", fractionPart.slice(0, maximumFractionDigits));
  }

  if (maximumFractionDigits > 0) {
    padded = intPart.concat(
      ".",
      fractionPart.padEnd(maximumFractionDigits, "0")
    );
  } else {
    padded = intPart;
  }

  return padded;
};
