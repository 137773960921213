/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * FX Trade
 */
export type GetTradesResponseItemsItem = {
  /**
   * The amount of the buy currency.
   */
  buy_amount: number;
  /**
   * The buy currency.
   */
  buy_currency: string;
  /**
   * The time the trade was created.
   */
  created_at: string;
  /**
   * The customer ID.
   */
  customer_id: string;
  /**
   * The fee for the trade.
   */
  fee: number;
  /**
   * The fee currency.
   */
  fee_currency: string;
  /**
   * The list of journal entry IDs.
   */
  journal_entry_ids: Array<string>;
  /**
   * The rate for the trade.
   */
  rate: number;
  /**
   * The amount of the sell currency.
   */
  sell_amount: number;
  /**
   * The sell currency.
   */
  sell_currency: string;
  /**
   * The status of the trade.
   */
  status: GetTradesResponseItemsItem.status;
  /**
   * The trade ID.
   */
  trade_id: string;
};

export namespace GetTradesResponseItemsItem {
  /**
   * The status of the trade.
   */
  export enum status {
    SUCCESSFUL = "SUCCESSFUL",
    PENDING = "PENDING",
    FAILED = "FAILED",
  }
}
