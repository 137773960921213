import store from "@two-ui/store";
import { RouteLocationNormalized } from "vue-router";

export class CustomerIdMissingNavigationError extends Error {
  constructor(message?: string) {
    super(message);
  }
}
/**
 * Checks if route requires customer id and is set.
 * If it requires and not set, if route opened directly via url, it returns to home page. If opened
 * using router navigation, throws customer error that can be handled using router.onError e.g. to show some feedback.
 * Returns undefined if there is no issue with performing the navigation.
 * @param to
 * @param from
 * @returns route or undefined
 */
export default function customerIdRestrictionGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) {
  if (to.meta.requiresCustomerId && !store.state.customerId) {
    // if route opened directly using url, move to home
    if (!from.name) return { name: "home" };
    // throw error that can be handled using router.onError
    throw new CustomerIdMissingNavigationError();
  }
}
