<!--
    A little bookmark like tag thing to link to the JIRA ticket for this branch
-->

<script lang="ts" setup>
const mode = import.meta.env.MODE;
const shouldShow = mode !== "production";

const branchName = import.meta.env.VITE_BRANCH;

const branchNameRegex = /^[A-z]+-[0-9]+/;
const jiraId = branchName?.match(branchNameRegex)?.[0].toUpperCase();
const jiraUrl = `https://wegift.atlassian.net/browse/${jiraId}`;
</script>

<template>
  <a
    v-if="shouldShow && jiraId"
    :href="jiraUrl"
    target="_blank"
    class="rotate-fixed-left fixed -top-4 right-0 z-50 -rotate-90 transform-gpu bg-indigo-500 px-2 pb-0.5 pt-0.5 text-sm font-bold lowercase text-white no-underline"
    :title="jiraId"
  >
    <font-awesome-icon :icon="['fab', 'jira']" />
  </a>
</template>

<style lang="sass" scoped>
.rotate-fixed-left
    transform-origin: bottom right
</style>
