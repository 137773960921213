export class AssertionError extends Error {
  constructor(message: string = "Assertion Error") {
    super(message);
    this.name = "AssertionError";
  }
}

export function assert(condition: any, msg?: string): asserts condition {
  if (!condition) {
    throw new AssertionError(msg);
  }
}
