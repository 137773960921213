<script lang="ts" setup>
import { trackEvent } from "@two-ui/mixpanel";
import { useRoute } from "vue-router";

trackEvent("Page not found", {
  path: useRoute().path,
});
</script>

<template>
  <main class="px-4">
    <div class="flex items-center justify-between pt-5">
      <h1>Page not found</h1>
    </div>
    <p>
      We couldn't find the page you requested,
      <router-link to="/">go back to home</router-link>?
    </p>
  </main>
</template>
