import { User as CmsUser } from "@workspace/open-api/cms";
import { HostedFields } from "braintree-web";
import { Dict } from "mixpanel-browser";
import { PaymentMethodResponse as SavedCard } from "@workspace/open-api/scs";
import { FeatureToggles } from "./store";
import { CompletedOrderItem } from "@workspace/open-api/ros-ui";
export interface CountryOption {
  label: string;
  isoCode: string;
  currency: string;
  isoCodeAlpha3: string;
  flag: string;
  currencySymbol: string;
  currencyFullText: string;
}

export interface CountryState {
  name: string;
  isoCode: string;
  countryIsoCode: string;
}

export interface LinkOrderItems {
  amount: number;
  quantity?: number;
  recipientEmails?: string[];
}

export interface LinkOrderItemsCSVDownload extends Record<string, any> {
  orderId: string;
  orderItemId: string;
  faceValue: number;
  currency: string;
  firstClickedAt: string;
  lastClickedAt: string;
  url: string;
}

export type OrderLinksTableRow = Pick<
  CompletedOrderItem,
  "id" | "redemption_url" | "payout"
> & {
  recipient?: string;
};

export enum ProductDenominationTypes {
  Open = "open",
  Fixed = "fixed",
}

export interface AccountDetailsFormItem {
  firstName: string;
  lastName: string;
  phone: string;
}

export interface CompanyDetailsFormItem {
  registeredName: string;
  tradingName: string | undefined;
  businessRegistrationNumber: string | undefined;
  employerIdentificationNumber: string | undefined;
  address: string;
  city: string;
  state: string;
  country: string;
  postCode: string;
}

// Object representing the payload of the JWT token
export type JwtPayload = {
  sub: string;
  aud: string;
  iss: string;
  iat: number;
  exp: number;
  azp: string;
  scope: string;
  permissions: string[];
  [key: string]: any;
};

export enum UserPermissions {
  READ_ORDERS = "read:orders",
  WRITE_ORDERS = "write:orders",
  CANCEL_ORDERS = "cancel:orders",
  READ_PRODUCTS = "read:products",
  READ_PRODUCT_APPROVALS = "read:product_approvals",
  WRITE_PRODUCT_APPROVALS = "write:product_approvals",
  READ_BILLING = "read:billing",
  WRITE_TOP_UP_BY_BANK = "write:top_up_by_bank",
  WRITE_TOP_UP_BY_CARD = "write:top_up_by_card",
  WRITE_TOP_UP_BY_FX = "write:top_up_by_fx",
  READ_ACTIVITY_HISTORY = "read:activity_history",
  DOWNLOAD_ACTIVITY_HISTORY = "download:activity_history",
  READ_PAYMENT_CARDS = "read:payment_cards",
  WRITE_PAYMENT_CARDS = "write:payment_cards",
  DELETE_PAYMENT_CARDS = "delete:payment_cards",
  READ_STATEMENTS = "read:statements",
  DOWNLOAD_STATEMENTS = "download:statements",
  READ_TEAM_MEMBERS = "read:team_members",
  WRITE_TEAM_MEMBERS = "write:team_members",
  DELETE_TEAM_MEMBERS = "delete:team_members",
  READ_API_KEYS = "read:api_keys",
  WRITE_API_KEYS = "write:api_keys",
  REVOKE_API_KEYS = "revoke:api_keys",
  READ_WEBHOOKS = "read:webhooks",
  WRITE_WEBHOOKS = "write:webhooks",
  DELETE_WEBHOOKS = "delete:webhooks",
}

export enum GuardAction {
  AND = "AND",
  OR = "OR",
}

export type PermissionGuard = {
  permissions: UserPermissions[];
  guardAction: GuardAction;
};

export type PermissionDirectiveBindingValue = Partial<
  PermissionGuard & { disableButtons: boolean }
>;

export interface UserSegmentFormItem {
  rewardReason: string[];
  rewardNeed: string;
  companySize: string;
}

export type UserFromToken = CmsUser | null | undefined;

// Mutually exclusive states a user can be on after authentication.
// Used for deciding default navigation after login for user.
export enum UserOnboardingState {
  UserInviteExpired = "UserInviteExpired",
  UserCreationRequired = "UserCreationRequired",
  BelongsToActiveCustomer = "BelongsToActiveCustomer",
  CustomerCreationRequired = "CustomerCreationRequired",
  CustomerApprovalRequired = "CustomerApprovalRequired",
  CustomerWithDomainAlreadyExists = "CustomerWithDomainExists",
  CustomerOnboardingTaskNotComplete = "CustomerOnboardingTaskNotComplete",
  UnknownState = "UnknownState",
}

export interface WistiaVideoEmbedOptions {
  // video link provided by Wistia embed code
  link: string;
  // video link container element class provided by Wistia embed code
  linkClass: string;
  // video container hashed ID provided in Wistia embed code
  containerHash: string;
  // Video title. Can be set to any custom value
  title: string;
}

export interface FileUploadItem {
  name: string;
  raw?: File;
}

export interface RewardCustomizationFile {
  name?: string;
  url?: string;
}

export interface RewardCustomizationValues {
  header: string;
  message: string;
  logo?: RewardCustomizationFile;
  media?: RewardCustomizationFile;
  colour: string;
}

// Object emitted by payment form on change which can be used to make a payment.
export interface PaymentFormData {
  hostedFields: HostedFields;
}

// Object representing row in csv or excel file
export type FileRow = Record<string, string>;

export interface OrderTypeOptionValues {
  name: string;
  title: string;
  imageUrl: string;
  description: string;
  isNewOption: boolean;
  routeName: string;
}

export interface OrderTrackingData {
  eventName: string;
  additionalProperties?: Dict;
}

export interface lineItem {
  quantity: number;
  face_value: number;
}

//Object with the label and description for the business registration field in user onboarding
export interface brnCopy {
  label: string;
  description: string;
}

export interface FloatAccount {
  currency: string;
  balance: number;
}

export type PaymentMethodSelection = SavedCard | FloatAccount;

export type SideBarItemsType = {
  routeName: string;
  displayName: string;
  icon: string[];
  propertyStore?: keyof FeatureToggles;
  isUserStaff?: boolean;
  isBeta?: boolean;
  showNewTag?: boolean;
  children?: SideBarItemsType[];
  permissionGuards?: PermissionGuard;
};

export type CatalogUIStatusType =
  | "INFORMATION_REQUESTED"
  | "PENDING"
  | "APPROVED"
  | "REQUIRES_CUSTOMER_ACTION";

export type CancellationResponse = {
  cancelFailed: boolean;
  message: string;
};

export type OrderHistoryFilters = {
  searchText: string | null;
  dateRange: {
    start: string;
    end: string;
  } | null;
};

export type TemplateProductsMapping = {
  [templateId: string]: string[];
};

export interface RegionInfo {
  heading: string;
  subheading: string;
  merchantName: string;
  productCode: string;
}
export enum RegionEnum {
  NorthAmerica = "North America",
  UK = "UK",
  Europe = "Europe",
  MiddleEast = "Middle East",
  Asia = "Asia",
  Africa = "Africa",
  Default = "Default",
}
export type BannerData = {
  [key in RegionEnum]: {
    heading: string;
    subheading: string;
    merchantName: string;
    productCode: string;
    backgroundImageId?: string;
    additionalImageId?: string;
    country: string;
    region: string;
    orderButtonLabel: string;
  };
};

export enum DownloadFileType {
  XLSX = "xlsx",
  CSV = "csv",
}
