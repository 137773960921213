import { useGlobalStore } from "@two-ui/global-store";
import { RouteLocationNormalized } from "vue-router";

export class FXRestrictionError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

/**
 * Checks if balances is enabled for a customer if required by route
 * @param to
 * @param from
 * @returns route or undefined
 */
export default function useFXRestrictionGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) {
  const globalStore = useGlobalStore();

  if (
    to.meta.requiresFXEnabledForCustomer &&
    !globalStore.customerFeatureFlags?.fx
  ) {
    // if route opened directly using url, move to home
    if (!from.name) return { name: "home" };
    // throw error that can be handled using router.onError
    throw new FXRestrictionError();
  }
}
