<template>
  <div class="flex items-start justify-start">
    <div
      class="field-focus flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-sm border-2 border-grey-100"
      :class="{
        'bg-primary': isChecked,
        'border-primary': isChecked,
        'bg-white': whiteBackground && !isChecked,
      }"
    >
      <input
        :id="id"
        type="checkbox"
        class="absolute opacity-0"
        :checked="isChecked"
        :value="value"
        @change="onChange"
        @blur="touched = true"
      />
      <svg
        v-if="isChecked"
        class="pointer-events-none h-3 w-3 fill-white"
        viewBox="0 0 20 20"
      >
        <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
      </svg>
    </div>
    <label
      :for="id"
      class="!mt-0 select-none pl-3"
      :class="[
        fontSize,
        {
          'checkbox-label-text': !showRequiredValidation,
          'checkbox-error-text': showRequiredValidation,
        },
      ]"
      ><slot
    /></label>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import { v4 as uuidv4 } from "uuid";

export default defineComponent({
  emits: ["update:modelValue", "change"],
  props: {
    /**
     * Can be a boolean or an array. In case of boolean, true false will be set based of checked state.
     * In case of array, if same array is passed to multiple checkboxes, it will be populated with all selected values passed through value prop.
     */
    modelValue: {
      type: [Boolean, Array] as PropType<boolean | string[]>,
    },
    /**
     * To control whether to make label red when not checked after touched.
     */
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "text-sm",
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const id = uuidv4();
    const touched = ref(false);
    const showRequiredValidation = computed(
      () =>
        touched.value &&
        props.required &&
        (!props.modelValue ||
          (Array.isArray(props.modelValue) && !props.modelValue.length))
    );
    const isChecked = computed(() => {
      if (Array.isArray(props.modelValue)) {
        return props.modelValue.includes(props.value);
      }
      return props.modelValue;
    });

    const onChange = (event: Event) => {
      let { modelValue } = props;
      const { checked } = event.target as HTMLInputElement;
      if (Array.isArray(modelValue)) {
        if (checked) {
          modelValue.push(props.value);
        } else {
          modelValue = modelValue.filter((value) => value !== props.value);
        }
        emit("update:modelValue", modelValue);
        emit("change", modelValue);
      } else {
        emit("update:modelValue", checked);
        emit("change", checked);
      }
    };
    return { id, onChange, showRequiredValidation, touched, isChecked };
  },
});
</script>
<style lang="sass" scoped>
@import "../styles/field"
.checkbox-label-text
  @apply font-normal text-black

.checkbox-error-text
  @apply mt-1 text-red-700
</style>
