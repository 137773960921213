<template>
  <div
    v-if="showDrawerBackShadow"
    @click="close"
    class="fixed inset-0 z-10 h-full w-full bg-black bg-opacity-40"
  ></div>
  <div
    class="fixed top-0 z-20 overflow-auto border border-grey-50 bg-white duration-500"
    :style="{
      ...drawerFixedStyles[direction],
      ...drawerCurrentVisibilityStyle,
    }"
    @click.stop
  >
    <div
      v-if="title"
      class="btn-secondary absolute left-4 top-9 text-black"
      :class="titleClass"
    >
      {{ title }}
    </div>
    <TwoButton
      circle
      @click="close"
      class="btn-secondary absolute right-4 top-8"
    >
      <font-awesome-icon :icon="['far', 'times']"></font-awesome-icon>
    </TwoButton>
    <div class="box-border h-full w-full p-4">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from "vue";
import TwoButton from "./TwoButton.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
  components: {
    TwoButton,
    FontAwesomeIcon,
  },
  emits: ["onClose", "update:show"],
  props: {
    show: {
      type: Boolean,
    },
    /**
     * Title to show on the top of the drawer
     */
    title: {
      type: String,
    },
    /**
     * Additional class to apply to title if customization needed
     */
    titleClass: {
      type: String,
      default: "",
    },
    /**
     * Size in px or percentage e.g. 100px, 40% etc. to control how much screen should be covered by the overlay when open.
     */
    size: {
      type: String,
      default: "100%",
    },
    /**
     * Direction where popover will open from, possible values: "top" and "right"
     */
    direction: {
      type: String as PropType<"top" | "right">, // We can add rest of the directions later if required.

      default: "top",
    },
  },
  setup(props, { emit }) {
    const showDrawerBackShadow = ref(false);
    const drawerFixedStyles = {
      top: {
        width: "100%",
        height: props.size,
        left: "0",
      },
      right: {
        width: props.size,
        height: "100%",
        right: "0",
      },
    };

    const drawerVisibleStyles = {
      top: {
        transform: "translateY(0)",
      },
      right: {
        transform: "translateX(0)",
      },
    };
    const drawerConainerhiddenStyles = {
      top: {
        transform: "translateY(-100%)",
      },
      right: {
        transform: "translateX(100%)",
      },
    };

    const drawerCurrentVisibilityStyle = ref(
      drawerConainerhiddenStyles[props.direction]
    );

    const showDrawer = () => {
      showDrawerBackShadow.value = true;
      drawerCurrentVisibilityStyle.value = drawerVisibleStyles[props.direction];
    };

    const hideDrawer = () => {
      drawerCurrentVisibilityStyle.value =
        drawerConainerhiddenStyles[props.direction];

      // wait for animation to finish before hiding back shadow
      setTimeout(() => {
        showDrawerBackShadow.value = false;
      }, 500);
    };
    watch(
      () => props.show,
      (showModelValue) => {
        if (showModelValue) showDrawer();
        else hideDrawer();
      }
    );
    const close = () => {
      emit("update:show", false);
      emit("onClose");
      hideDrawer();
    };

    return {
      close,
      drawerFixedStyles,
      showDrawerBackShadow,
      drawerCurrentVisibilityStyle,
    };
  },
});
</script>
