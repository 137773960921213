<script setup lang="ts">
import TwoButton from "./TwoButton.vue";
import { computed, onUnmounted, PropType, ref, watch } from "vue";
import { assert } from "../util/typescript";
import { v4 as uuidv4 } from "uuid";
import { FileUploadItem, PreSelectedFile } from "./types";
import { getFileUploadItemsFromFileList } from "../util/file";
import TwoFileList from "./TwoFileList.vue";
const props = defineProps({
  label: {
    type: String,
  },
  description: {
    type: String,
  },
  /**
   * To show text indicating field is required below the field after touched.
   */
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
  },
  /**
   * If set to true, file upload starts automatically after selecting file.
   in case its false, change event can be used to get file and perform upload manually
   */
  autoUpload: { type: Boolean, default: true },
  /**
   * Action url for uploading the resource
   */
  action: { type: String },
  /**
   * String that defines the file types the file input should accept e.g. .doc,.docx,application/json
   */
  accept: { type: String },
  /**
   * Additional data to set with file upload's FormData
   */
  data: { type: Object as PropType<Record<string, any>>, default: {} },
  /**
   * Key name for the file to upload in FormData
   */
  name: { type: String, default: "file" },
  /**
   * Used for showing an already selected file. Useful for pre filled forms
   */
  preSelectedFiles: { type: Array as PropType<PreSelectedFile[]> },
  /**
   * http method for uploading file e.g. PUT, POST
   */
  method: {
    type: String,
    default: "POST",
  },
  maxSizeKb: {
    type: Number,
  },
  /**
   * Used to indicate in label that field is optional
   */
  showOptionalHint: { type: Boolean, default: false },
  /**
   * Used to control multi file selection and whether doing new selection adds new files instead of replacing
   */
  multiple: {
    type: Boolean,
    default: false,
  },
  /**
   * Function if custom implementation of file upload is needed. Must return fetch API response promise
    example use case: get s3 signed url and upload file there
   */
  uploadFileFn: {
    type: Function as PropType<(file: FileUploadItem) => Promise<Response>>,
  },
  /**
   * Maximum number of files that can be selected
   */
  maxFiles: {
    type: Number,
    default: undefined, // no limit
  },
});
const emit = defineEmits(["change", "validityChange", "uploadSuccess"]);

const inputFileRef = ref<HTMLInputElement>();
const touched = ref(false);
const selectedFiles = ref<FileUploadItem[]>(
  props.preSelectedFiles?.map((file) => ({
    ...file,
    // set uploaded to true as pre selected files are already uploaded.
    uploaded: true,
    uploading: false,
    preSelected: true,
  })) || []
);
const id = uuidv4();

const openFilePicker = () => {
  if (inputFileRef.value) {
    inputFileRef.value.click();
  }
};

/**
 * Sets validation error for each file and returns boolean indicated if all files valid or not
 */
const validateFiles = (selectedFiles: FileUploadItem[]) => {
  return selectedFiles.map((file) => {
    if (!file.raw) {
      file.validationError = "Unknown error";
    }
    if (file.raw && isFileSizeGreaterThanAllowedSize(file.raw)) {
      file.validationError = `File size cannot be greater than ${(
        props.maxSizeKb! / 1024
      ).toFixed(2)} MB`;
    }
    return file;
  });
};

/**
 * Sets url field for each file that can be used to preview file
 */
const setFilesPreviewUrls = (files: FileUploadItem[]) => {
  return files.map((file) => {
    if (file.raw) file.url = URL.createObjectURL(file.raw);
    return file;
  });
};

const onFileChange = async (event: Event) => {
  const fileInput = event.target as HTMLInputElement;
  let fileUploadItems = getFileUploadItemsFromFileList(fileInput.files) || [];
  fileUploadItems = validateFiles(fileUploadItems);
  fileUploadItems = setFilesPreviewUrls(fileUploadItems);

  // Maximum number of files validation
  if (props.maxFiles !== undefined) {
    const totalFiles = selectedFiles.value.length + fileUploadItems.length;
    if (totalFiles > props.maxFiles) {
      alert(`You can only select up to ${props.maxFiles} files.`);
      return;
    }
  }

  if (props.multiple) {
    selectedFiles.value = [...selectedFiles.value, ...fileUploadItems];
  } else {
    selectedFiles.value = fileUploadItems;
  }
  const allFilesValid = fileUploadItems.every((file) => !file.validationError);
  if (!allFilesValid) {
    return;
  }
  emit("change", selectedFiles.value);
  if (props.autoUpload) {
    await uploadSelectedFiles();
    emit("change", selectedFiles.value);
    if (!fieldInvalid.value) {
      // all files uploaded successfully
      emit("uploadSuccess", selectedFiles.value);
    }
  }
};

const uploadSelectedFiles = async () => {
  return new Promise((resolve) => {
    let uploadedFilesCount = 0;
    const filesToUpload = selectedFiles.value.filter(
      (file) => !file.uploaded && !file.uploadError
    );
    for (const file of filesToUpload) {
      let uploadFileFn: Promise<Response>;
      if (props.uploadFileFn) uploadFileFn = props.uploadFileFn(file);
      else {
        const selectedFileRaw = file.raw;
        const formData = new FormData();
        assert(selectedFileRaw, "Couldn't find file to upload.");
        // append additional data to form data
        Object.entries(props.data).forEach((entry) => {
          formData.append(entry[0], entry[1]);
        });
        formData.append("Content-Type", selectedFileRaw.type);
        formData.append(props.name, selectedFileRaw);
        assert(props.action, "Missing action url");
        // upload using fetch API
        uploadFileFn = fetch(props.action, {
          method: props.method,
          body: formData,
        });
      }
      file.uploading = true;
      uploadFileFn
        .then((result) => {
          file.uploading = false;
          file.uploadResponse = result;
          file.uploaded = true;
          if (!result.ok) throw new Error(result.statusText);
          if (++uploadedFilesCount === filesToUpload.length) {
            // resolve as all files uploaded
            resolve(true);
          }
        })
        .catch((ex) => {
          file.uploading = false;
          file.uploadResponse = ex;
          file.uploadError =
            "An error occurred while uploading file: " + file.name;
          if (++uploadedFilesCount === filesToUpload.length) {
            // resolve as all files uploaded or errored out
            resolve(true);
          }
        });
    }
  });
};

const isFileSizeGreaterThanAllowedSize = (file: File) => {
  const fileSize = file.size / 1024;
  return !!(props.maxSizeKb && fileSize > props.maxSizeKb);
};
const removeFile = (fileName: string) => {
  selectedFiles.value = selectedFiles.value.filter(
    (file) => file.name !== fileName
  );
  emit("change", selectedFiles.value);
};
const fieldInvalidDueToRequiredValidation = computed(
  () => props.required && !selectedFiles.value.length
);

const fieldInvalid = computed(
  () =>
    fieldInvalidDueToRequiredValidation.value ||
    selectedFiles.value.some((file) => file.uploadError || file.validationError)
);

watch(
  () => fieldInvalid.value,
  (value) => {
    emit("validityChange", !value);
  },
  { immediate: true }
);

const removeInputElementFile = () => {
  if (inputFileRef.value) inputFileRef.value.value = "";
};

onUnmounted(() => {
  // revoke all object urls
  selectedFiles.value.forEach((file) => {
    if (file.url) URL.revokeObjectURL(file.url);
  });
});
</script>

<template>
  <div>
    <label :for="id" class="field-label-text" v-if="label"
      >{{ label }}
      <span class="field-optional-hint" v-if="showOptionalHint"
        >(optional)</span
      >
    </label>
    <p class="field-description-text" v-if="description">{{ description }}</p>
    <div class="mt-2">
      <div
        class="inline-block rounded-lg focus-within:ring-1 focus-within:ring-black"
      >
        <input
          ref="inputFileRef"
          @change="onFileChange"
          @click="removeInputElementFile"
          type="file"
          class="absolute opacity-0"
          :accept="accept"
          :disabled="disabled"
          :multiple="multiple"
          @blur="touched = true"
        />
        <TwoButton
          tabindex="-1"
          class="btn-secondary"
          :disabled="disabled"
          @click="openFilePicker"
          ><slot
        /></TwoButton>
      </div>
      <TwoFileList
        class="mt-2"
        @remove-file="removeFile"
        :files="selectedFiles"
        :disabled="disabled"
      />
      <p
        v-if="touched && fieldInvalidDueToRequiredValidation"
        class="field-error-text"
      >
        This field is required
      </p>
    </div>
  </div>
</template>
<style lang="sass" scoped>
@import "../styles/field"
</style>
