<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
  class?: string;
}>();

const combinedClass = computed(() => {
  const baseClass = "inline-block rounded-2xl px-2 py-1 font-bold";
  const defaultStyle = "bg-grey-800 text-grey-100";
  if (props.class) {
    return `${baseClass} ${props.class}`;
  }
  return `${baseClass} ${defaultStyle}`;
});
</script>

<template>
  <span :class="combinedClass">
    <slot />
  </span>
</template>
