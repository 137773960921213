/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SubscriptionPlans = {
  /**
   * The unit of time for the subscription plan. Currently only DAYS and MONTHS are supported.
   */
  length_unit?: SubscriptionPlans.length_unit;
  length?: number;
  name?: string;
  price?: number;
  /**
   * This is the code you'll use during order creation, rather than the top level code.
   */
  subscription_plan_code?: string;
};

export namespace SubscriptionPlans {
  /**
   * The unit of time for the subscription plan. Currently only DAYS and MONTHS are supported.
   */
  export enum length_unit {
    DAYS = "DAYS",
    MONTHS = "MONTHS",
  }
}
